<template>
  <v-app>
    <v-app-bar dark app color="white">
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xsOnly && ActionAvailable" color="black" @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-img height="70px" width="100px" contain :src="require('@/assets/headerlogo.png')" />
      <div v-if="ActionAvailable">
        <v-btn class="hidden-xs-only" text v-for="item in menuButtons" color="red darken-2" style="font-weight: 700" :key="item.name" v-text="item.name" :href="item.route"/>
        <v-btn class="hidden-xs-only" text color="red darken-2" style="font-weight: 700" @click="openPdf()" >Flyer <v-icon>mdi-file-pdf</v-icon></v-btn>
      </div>
      <v-spacer/>
    </v-app-bar>
    <v-navigation-drawer v-if="$vuetify.breakpoint.xsOnly && ActionAvailable" app v-model="drawer" width="60vw">
      <div>
        <div class="d-flex justify-start">
          <v-btn v-if="drawer" class="mt-3 animate__animated animate__fadeInDown" text @click="drawer = !drawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="d-flex justify-center align-center">
          <v-list>
          <v-list-item v-for="item in menuButtons" :key="item.name" :href="item.route" style="width:100%">
            <v-list-item-content>{{ item.name }}</v-list-item-content>
          </v-list-item>
        </v-list>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main>
        <router-view></router-view>
    </v-main>
    <v-footer>
      <v-card-text class="text-center">
        * alle Preise inkl. 19% MwSt.
      </v-card-text>
      <v-card-text v-if="ActionAvailable">
        <v-btn text @click="openPdf()">Küchentrends PDF<v-icon>mdi-file-pdf</v-icon></v-btn>
      </v-card-text>
      <v-card-text>
        <router-link class="footerlinks" to="/impressum">
          Impressum
        </router-link>
      </v-card-text>
      <v-card-text>
        <router-link class="footerlinks" to="/datenschutz">
          Datenschutz
        </router-link>
      </v-card-text>
      <div style="width:100%" class="d-flex justify-center">
        <v-card-text>&copy; {{new Date().getFullYear()}} - KÜCHENPROFI GmbH</v-card-text>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import 'animate.css'
export default {
  data() {
    return {
      drawer: false,
      menuButtons: [
        { name: 'Start', route: '/',},
        { name: 'Produkte', route: '/#products'},
        { name: 'Händler', route: '/#stores'}
      ]
    }
  },
  methods: {
    openPdf() {
      const link = "./Küchentrends_Katalog_2023.pdf"
      window.open(link, 'blank')
    }
  }
}
</script>


<style lang="scss">

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.footerlinks {
  text-decoration: none;
  color: black !important;
}
.v-footer{
  margin-top: 50px;
}
</style>
