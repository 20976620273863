<template>
  <div>
    <v-img position="center top" width="100%" :height="$vuetify.breakpoint.mdAndDown?'300' :'600'" :class="$vuetify.breakpoint.mdAndDown? '' : 'parallax-image'"  :src="require('@/assets/headerCooking.jpg')" />
    <v-container v-if="ActionAvailable">
      <!-- Produkte -->
      <v-card-title id="products" class="text-h1 pt-10">Produkte</v-card-title>
      <v-row justify="center">
        <v-col v-for="(item, idx) in flexibleCountProdukte" :key="idx" cols="12" sm="4" md="3" class="mb-5">
          <product-card :item="item" />
        </v-col>
        <v-col cols="12">
          <v-btn class="mt-5" @click="showAll = !showAll" text color="primary">{{showAll? "Weniger anzeigen" : "Alle anzeigen"}}</v-btn> <!-- Würde hier das Array an produkten einfach wechseln -->
        </v-col>
      </v-row>
      <!-- Stores -->
      <v-card-title class="text-h1 pt-10" id="stores">Händler</v-card-title>
      <!-- <test /> -->
      <div id="GoogleMapsWarning">
        <p>Beim Klick auf den Button "Google Karte anzeigen" wird Ihre IP-Adresse an die Server von Google gesendet und unsere Händler-Karte wird geladen.<br>
          Nähere Informationen entnehmen Sie bitte vorab der Seite <router-link to="/Datenschutz">Datenschutz</router-link>.</p>
        <p><button @click="showGoogleMap"><v-icon>mdi-map-search</v-icon> Google Karte anzeigen</button></p>
      </div>
      <map-search :standorte="markers" v-if="googleMapVisible"></map-search>
      <v-card-title class="text-h1">Marken</v-card-title>
      <v-row class="mx-7" justify="center" align="center">
        <v-col v-for="item in marken" :key="item.image" @click="routeToMarke(item)" style="cursor:pointer" cols="12" sm="6" md="3">
          <v-img contain max-height="240px" :src="item.image" />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-card-title class="text-h1">
        Küchentrends Aktion
      </v-card-title>
      <v-card-text class="text-start text-aktion">
        <p>Ab <strong>November 2024</strong> erwarten Sie auf dieser Seite wieder unsere aktuellen Küchentrends.</p>
        <p>Besuchen Sie bis dahin gerne die Webseiten unser Marken:</p>
      </v-card-text>
      <v-row class="mx-7" justify="center" align="center">
        <v-col v-for="item in marken" :key="item.image" @click="routeToMarke(item)" style="cursor:pointer" cols="12" sm="6" md="3">
          <v-img contain max-height="200px" :src="item.image" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import productCard from '@/components/productCard'
import Kunden from '@/assets/Kunden.js'
import Produkte from '@/assets/Produkte.js'
import MapSearch from '@/components/MapSearch'
/* import Test from '@/components/Test' */
export default {
  components: {
    productCard,
    MapSearch,
    /* Test */
  },
  data: () => ({
    googleMapVisible: false,
    dummyProdukts: [
      { name: 'Dummy Produkt' }
    ],
    standorte: Kunden.Kunden,
    produkte: Produkte.Produkte,
    markers: null,
    showAll: false,
    marken: [
      {image: require('@/assets/marken/cilio.png'), url:'https://www.cilio.de/'},
      {image: require('@/assets/marken/kuechenprofi.png'), url:'https://www.kuechenprofi.de/'},
      {image: require('@/assets/marken/spring.png'), url: 'https://www.spring.ch/'},
      {image: require('@/assets/marken/zassenhaus.png'), url:'https://www.zassenhaus.com/'},
    ]
  }),
  watch: {
    standorte: {
      immediate: true,
      handler(val) {
        this.changeToMarkerSyntax(val)
      }
    }
  },
  computed: {
    flexibleCountProdukte() {
      const ifMobile = this.$vuetify.breakpoint.xsOnly ? 5 : 12
      if(this.showAll) {
        return this.produkte
      } else {
        return this.produkte.slice(0, ifMobile)
      }
    }
  },
  methods: {
    showGoogleMap(){
      this.googleMapVisible = true;
      document.getElementById("GoogleMapsWarning").hidden = true;
    },
    changeToMarkerSyntax(standorte) {
      for(let i = 0; i < standorte.length; i++){
        const newCoordinates = standorte[i].Coordinates.split(",")
        standorte[i].Coordinates = {
          lat: parseFloat(newCoordinates[0]),
          lng: parseFloat(newCoordinates[1])
        }
      }
      this.markers = standorte.map(a => ({... a}))
    },
    routeToMarke(item) {
      window.open(item.url, '_blank')
    },
  }
}
</script>
<style lang="scss">
.parallax-image {
  .v-responsive__sizer {
    padding-bottom: unset!important;
  }
  .v-image__image {
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
}
.text-aktion{
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 60px;
  line-height: 1.5;
}
</style>
