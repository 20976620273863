export default {
  Kunden:
  [
{
      "Id": 1,
      "Name": "Elektro MERL",
      "Strasse": "Mittergasse",
      "Hausnr": "7",
      "PLZ": "8600",
      "Ort": "Bruck an der Mur",
      "Webseite": "https://merl.at/",
      "Telefonnummer": "03862 51 222",
      "Mail": "verkauf@merl.at",
      "Logo": "",
      "Coordinates": "47.4104399, 15.2707658",
      "Logopfad": ""
    },{
      "Id": 2,
      "Name": "Kaufhaus Thaller",
      "Strasse": "Hauptplatz",
      "Hausnr": "22",
      "PLZ": "8330",
      "Ort": "Feldbach",
      "Webseite": "https://kaufhaus-thaller.at",
      "Telefonnummer": "03152 24010",
      "Mail": "office@kaufhaus-thaller.at",
      "Logo": "",
      "Coordinates": "46.953178, 15.8876366",
      "Logopfad": ""
    },{
      "Id": 3,
      "Name": "RAUCH - Alles für den Haushalt",
      "Strasse": "Grazbachgasse",
      "Hausnr": "5",
      "PLZ": "8010",
      "Ort": "Graz ",
      "Webseite": "https://rauch-online.at/",
      "Telefonnummer": "0316 837537",
      "Mail": "office@rauch-online.at",
      "Logo": "",
      "Coordinates": "47.0661825, 15.4461724",
      "Logopfad": ""
    },{
      "Id": 4,
      "Name": "RED ZAC Kappacher",
      "Strasse": "Hauptstr.",
      "Hausnr": "45",
      "PLZ": "5600",
      "Ort": "St. Johann",
      "Webseite": "https://www.redzac.at/redzac-kappacher/info/tischkultur",
      "Telefonnummer": "06412 20020",
      "Mail": "shop.kappacher@redzac.at",
      "Logo": "",
      "Coordinates": "47.3479687, 13.2020938",
      "Logopfad": ""
    },{
      "Id": 5,
      "Name": "TAVOLA Kochen Genießen Schenken",
      "Strasse": "Im Hatric",
      "Hausnr": "11, Top 21",
      "PLZ": "8230",
      "Ort": "Hartberg",
      "Webseite": "https://www.tavola.at/",
      "Telefonnummer": "03332 64080",
      "Mail": "office@tavola.at",
      "Logo": "",
      "Coordinates": "47.2824568, 15.9939932",
      "Logopfad": ""
    },{
      "Id": 6,
      "Name": "A. Oster",
      "Strasse": "Ostendorfplatz ",
      "Hausnr": "5",
      "PLZ": "76199",
      "Ort": "Karlsruhe",
      "Webseite": "https://www.oster-ka.de",
      "Telefonnummer": "0721 890106",
      "Mail": "info@oster-ka.de",
      "Logo": "",
      "Coordinates": "48.9760842, 8.4070671",
      "Logopfad": ""
    },{
      "Id": 7,
      "Name": "Amend",
      "Strasse": "Bergstraße",
      "Hausnr": "103",
      "PLZ": "69469",
      "Ort": "Weinheim",
      "Webseite": "https://www.amend-weinheim.de/wohnen",
      "Telefonnummer": "06201 99000",
      "Mail": "info@amend-weinheim.de",
      "Logo": "",
      "Coordinates": "49.5592193, 8.6687577",
      "Logopfad": ""
    },{
      "Id": 8,
      "Name": "ARS COQUENDI - Die Kunst des Kochens",
      "Strasse": "Oeseder Str.",
      "Hausnr": "105",
      "PLZ": "49124",
      "Ort": "GM Hütte-Oesede",
      "Webseite": "https://www.arscoquendi.de/index.html",
      "Telefonnummer": "05401 3659898",
      "Mail": "mail@arscoquendi.de",
      "Logo": "",
      "Coordinates": "52.2028489, 8.0664702",
      "Logopfad": ""
    },{
      "Id": 9,
      "Name": "Atterer Kochen und Schenken",
      "Strasse": "Eberle-Kögl-Str.",
      "Hausnr": "20",
      "PLZ": "87616",
      "Ort": "Marktoberdorf",
      "Webseite": "https://www.atterer.shop/",
      "Telefonnummer": "08342 964180",
      "Mail": "info@atterer.shop",
      "Logo": "",
      "Coordinates": "47.7779563, 10.6212197",
      "Logopfad": ""
    },{
      "Id": 10,
      "Name": "B. Schlottig GmbH",
      "Strasse": "Lorbeerstraße",
      "Hausnr": "1",
      "PLZ": "09496",
      "Ort": "Marienberg",
      "Webseite": "https://bastelwunder.de/",
      "Telefonnummer": "03735 6609909",
      "Mail": "bastelwunder@online.de",
      "Logo": "",
      "Coordinates": "50.6499131, 13.1636268",
      "Logopfad": ""
    },{
      "Id": 11,
      "Name": "Bantel GmbH",
      "Strasse": "Johann-Philipp-Palm-Straße",
      "Hausnr": "23",
      "PLZ": "73614",
      "Ort": "Schorndorf",
      "Webseite": "https://bantel.de/",
      "Telefonnummer": "07181 20090",
      "Mail": "mail@bantel.de",
      "Logo": "",
      "Coordinates": "48.805897, 9.5298032",
      "Logopfad": ""
    },{
      "Id": 12,
      "Name": "Benner",
      "Strasse": "Burgstraße",
      "Hausnr": "33-35",
      "PLZ": "49808",
      "Ort": "Lingen",
      "Webseite": "https://www.benner-lingen.de",
      "Telefonnummer": "0591 3423",
      "Mail": "info@benner-lingen.de",
      "Logo": "",
      "Coordinates": "52.5228747, 7.3208575",
      "Logopfad": ""
    },{
      "Id": 13,
      "Name": "Bergglas",
      "Strasse": "Markt",
      "Hausnr": "16-17",
      "PLZ": "08340",
      "Ort": "Schwarzenberg",
      "Webseite": "https://www.bergglas.de/",
      "Telefonnummer": "03774 23211",
      "Mail": "shop@bergglas.de",
      "Logo": "",
      "Coordinates": "50.5374799, 12.7836854",
      "Logopfad": ""
    },{
      "Id": 14,
      "Name": "BEST. Haushalt Küche & Genuss",
      "Strasse": "Hauptstr.",
      "Hausnr": "28",
      "PLZ": "77716",
      "Ort": "Haslach im Kinzigtal",
      "Webseite": "https://www.besthaushalt.de/",
      "Telefonnummer": "07832 976500",
      "Mail": "info@besthaushalt.de",
      "Logo": "",
      "Coordinates": "48.2767161, 8.0882074",
      "Logopfad": ""
    },{
      "Id": 15,
      "Name": "Carl Hezel Baufachmarkt GmbH",
      "Strasse": "Dinkelsbühler Straße",
      "Hausnr": "24",
      "PLZ": "91555",
      "Ort": "Feuchtwangen",
      "Webseite": "https://bauklotz-hezel.de/haushaltswaren.html",
      "Telefonnummer": "09852 67010",
      "Mail": "info@bauklotz-hezel.de",
      "Logo": "",
      "Coordinates": "49.1636615, 10.3257284",
      "Logopfad": ""
    },{
      "Id": 16,
      "Name": "Carl Schäffer",
      "Strasse": "Nikolaiort ",
      "Hausnr": "6-9",
      "PLZ": "49074",
      "Ort": "Osnabrück",
      "Webseite": "https://www.schaeffer24.de",
      "Telefonnummer": "5413313148",
      "Mail": "info@schaeffer24.de",
      "Logo": "",
      "Coordinates": "52.2755993, 8.04452",
      "Logopfad": ""
    },{
      "Id": 17,
      "Name": "Carl Stieß",
      "Strasse": "Am Waisenhausplatz",
      "Hausnr": "10",
      "PLZ": "75172",
      "Ort": "Pforzheim",
      "Webseite": "https://carl-stiess.de",
      "Telefonnummer": "07231 33702",
      "Mail": "info@carl-stiess.de",
      "Logo": "",
      "Coordinates": "48.890304, 8.7010467",
      "Logopfad": ""
    },{
      "Id": 18,
      "Name": "Coledampf's",
      "Strasse": "Fechnerstr.",
      "Hausnr": "27",
      "PLZ": "10717",
      "Ort": "Berlin",
      "Webseite": "https://coledampfs-culturcentrum.de",
      "Telefonnummer": "030 8839191",
      "Mail": "berlin@coledampfs.de",
      "Logo": "",
      "Coordinates": "52.4889884, 13.319725",
      "Logopfad": ""
    },{
      "Id": 19,
      "Name": "CUCINA Düsseldorf",
      "Strasse": "Carlsplatz",
      "Hausnr": "24",
      "PLZ": "40213",
      "Ort": "Düsseldorf",
      "Webseite": "https://www.cucina-duesseldorf.de/index.html",
      "Telefonnummer": "0211 88287210",
      "Mail": "HALLO@CUCINA-DDORF.DE",
      "Logo": "",
      "Coordinates": "51.2239025, 6.77317",
      "Logopfad": ""
    },{
      "Id": 20,
      "Name": "Culinaris Berlin",
      "Strasse": "Schloßstrasse ",
      "Hausnr": "1",
      "PLZ": "12163 ",
      "Ort": "Berlin",
      "Webseite": "https://culinaris.eu",
      "Telefonnummer": "030 79786570",
      "Mail": "service@culinaris.eu",
      "Logo": "",
      "Coordinates": "52.4646622, 13.3266775",
      "Logopfad": ""
    },{
      "Id": 21,
      "Name": "Culinaris ELBE Einkaufszentrum",
      "Strasse": "Osdorfer Landstraße",
      "Hausnr": "131 - 135",
      "PLZ": "22609",
      "Ort": "Hamburg",
      "Webseite": "https://culinaris.eu",
      "Telefonnummer": "040 30377770 ",
      "Mail": "service@culinaris.eu",
      "Logo": "",
      "Coordinates": "53.5707585, 9.8612222",
      "Logopfad": ""
    },{
      "Id": 22,
      "Name": "Culinaris Erfurt",
      "Strasse": "Futterstrasse ",
      "Hausnr": "7",
      "PLZ": "99084",
      "Ort": "Erfurt",
      "Webseite": "https://culinaris.eu",
      "Telefonnummer": "0361 6635986",
      "Mail": "service@culinaris.eu",
      "Logo": "",
      "Coordinates": "50.9789282, 11.032096",
      "Logopfad": ""
    },{
      "Id": 23,
      "Name": "Culinaris Leipzig",
      "Strasse": "Grimmaische Str.",
      "Hausnr": "25",
      "PLZ": "04109",
      "Ort": "Leipzig",
      "Webseite": "https://culinaris.eu",
      "Telefonnummer": "0341 9615947",
      "Mail": "service@culinaris.eu",
      "Logo": "",
      "Coordinates": "51.3398313, 12.3788669",
      "Logopfad": ""
    },{
      "Id": 24,
      "Name": "Culinaris NOVA Shoppingcenter",
      "Strasse": "Einkaufszentrum NOVA",
      "Hausnr": "",
      "PLZ": "06237",
      "Ort": "Leuna OT Günthersdorf",
      "Webseite": "https://culinaris.eu",
      "Telefonnummer": "034638 39374",
      "Mail": "service@culinaris.eu",
      "Logo": "",
      "Coordinates": "51.3442624, 12.1777949",
      "Logopfad": ""
    },{
      "Id": 25,
      "Name": "Daubenbüchel",
      "Strasse": "Schloßstraße",
      "Hausnr": "68",
      "PLZ": "51429",
      "Ort": "Bergisch Gladbach",
      "Webseite": "https://www.daubenbuechel.de/",
      "Telefonnummer": "02204 52497",
      "Mail": "info@daubenbuechel.de",
      "Logo": "",
      "Coordinates": "50.9646511, 7.1614453",
      "Logopfad": ""
    },{
      "Id": 26,
      "Name": "Daume",
      "Strasse": "Kempener Str.",
      "Hausnr": "235-243",
      "PLZ": "51467",
      "Ort": "Bergisch Gladbach",
      "Webseite": "https://www.daume.com/",
      "Telefonnummer": "02202 988740",
      "Mail": "mail@daume.com",
      "Logo": "",
      "Coordinates": "51.012015, 7.0806939",
      "Logopfad": ""
    },{
      "Id": 27,
      "Name": "Demmer Haus der Geschenke",
      "Strasse": "Wirichsbongardstraße",
      "Hausnr": "1-3",
      "PLZ": "52062",
      "Ort": "Aachen",
      "Webseite": "https://demmer-aachen.com/",
      "Telefonnummer": "0241 479900",
      "Mail": "info@demmer-aachen.com",
      "Logo": "",
      "Coordinates": "50.773603, 6.0873899",
      "Logopfad": ""
    },{
      "Id": 28,
      "Name": "Der kleine Küchenladen",
      "Strasse": "Limastraße",
      "Hausnr": "2",
      "PLZ": "14163",
      "Ort": "Berlin-Zehlendorf",
      "Webseite": "http://www.der-kleine-kuechenladen.de/",
      "Telefonnummer": "030 36752333",
      "Mail": "kontakt@der-kleine-kuechenladen.de",
      "Logo": "",
      "Coordinates": "52.4382067, 13.2314616",
      "Logopfad": ""
    },{
      "Id": 29,
      "Name": "Der Küchenhelfer",
      "Strasse": "Kirchplatz",
      "Hausnr": "11",
      "PLZ": "34346",
      "Ort": "Hann. Münden",
      "Webseite": "https://www.facebook.com/people/Der-Küchenhelfer/100066475250091/",
      "Telefonnummer": "5541 9548845",
      "Mail": "derkuechenhelfer@gmx.de",
      "Logo": "",
      "Coordinates": "51.4164092, 9.6505222",
      "Logopfad": ""
    },{
      "Id": 30,
      "Name": "Der Küchenwicht",
      "Strasse": "Uhlstr.",
      "Hausnr": "66",
      "PLZ": "50321",
      "Ort": "Brühl",
      "Webseite": "http://www.kuechenwicht.de",
      "Telefonnummer": "02232 949572",
      "Mail": "info@kuechenwicht.de",
      "Logo": "",
      "Coordinates": "50.8267095, 6.9030205",
      "Logopfad": ""
    },{
      "Id": 31,
      "Name": "Die Kommode",
      "Strasse": "Am Maubishof",
      "Hausnr": "8",
      "PLZ": "41564",
      "Ort": "Kaarst",
      "Webseite": "https://diekommode-kaarst.de/",
      "Telefonnummer": "02131 5129944",
      "Mail": "team@diekommode-kaarst.de",
      "Logo": "",
      "Coordinates": "51.2273448, 6.6190438",
      "Logopfad": ""
    },{
      "Id": 32,
      "Name": "Dieter Grabert",
      "Strasse": "Austr.",
      "Hausnr": "5",
      "PLZ": "74613",
      "Ort": "Öhringen",
      "Webseite": "https://www.grabert.de",
      "Telefonnummer": "0794 192810",
      "Mail": "info@grabert.de",
      "Logo": "",
      "Coordinates": "49.2046083, 9.4891236",
      "Logopfad": ""
    },{
      "Id": 33,
      "Name": "Dietterlein",
      "Strasse": "Gartenstraße",
      "Hausnr": "38",
      "PLZ": "72764",
      "Ort": "Reutlingen",
      "Webseite": "https://www.dietterlein.de",
      "Telefonnummer": "07121329213",
      "Mail": "dietterlein@t-online.de",
      "Logo": "",
      "Coordinates": "48.4921069, 9.2145909",
      "Logopfad": ""
    },{
      "Id": 34,
      "Name": "Eberl Großmarkt",
      "Strasse": "Gaildorfer Straße",
      "Hausnr": "10",
      "PLZ": "74564",
      "Ort": "Crailsheim",
      "Webseite": "https://eberl-crailsheim.de",
      "Telefonnummer": "07951 914120",
      "Mail": "haushalt@eberl-franz.de",
      "Logo": "",
      "Coordinates": "49.1340894, 10.062727",
      "Logopfad": ""
    },{
      "Id": 35,
      "Name": "Franz Scholand",
      "Strasse": "Hauptstr.",
      "Hausnr": "153",
      "PLZ": "58332",
      "Ort": "Schwelm",
      "Webseite": "https://www.franz-scholand.de",
      "Telefonnummer": "02336 2166",
      "Mail": "info@franz-scholand.de",
      "Logo": "",
      "Coordinates": "51.2893056, 7.3018384",
      "Logopfad": ""
    },{
      "Id": 36,
      "Name": "Friedrich Ahrens",
      "Strasse": "Mittlere Straße",
      "Hausnr": "19-21",
      "PLZ": "32676",
      "Ort": "Lügde",
      "Webseite": "https://www.friedrich-ahrens-kg.de",
      "Telefonnummer": "05281 79212",
      "Mail": "info@ahrens-homeandgarden.de",
      "Logo": "",
      "Coordinates": "51.9585767, 9.246266",
      "Logopfad": ""
    },{
      "Id": 37,
      "Name": "Friedrich Ellsiepen Eisenwaren",
      "Strasse": "Im Spring",
      "Hausnr": " 2 -4",
      "PLZ": "42489",
      "Ort": " Wülfrath",
      "Webseite": "",
      "Telefonnummer": "02058 2531",
      "Mail": "Friedrich.ellsiepen@t-online.de  ",
      "Logo": "",
      "Coordinates": "51.2811568, 7.035164",
      "Logopfad": ""
    },{
      "Id": 38,
      "Name": "Fritz Grossmann",
      "Strasse": "Tannenburgstr.",
      "Hausnr": "17-19",
      "PLZ": "88048",
      "Ort": "Friedrichshafen",
      "Webseite": "https://grossmann-fn.net",
      "Telefonnummer": "07541 60360",
      "Mail": "info@grossmann-fn.de",
      "Logo": "",
      "Coordinates": "47.6847494, 9.4443472",
      "Logopfad": ""
    },{
      "Id": 39,
      "Name": "Georg N. Sonna e.K.",
      "Strasse": "Oberer Markt ",
      "Hausnr": "11",
      "PLZ": "92637",
      "Ort": "Weiden in der Oberpfalz",
      "Webseite": "www.sonna-weiden.de",
      "Telefonnummer": "0961 44639",
      "Mail": "beratung@sonna-weiden.de",
      "Logo": "",
      "Coordinates": "49.6749542, 12.1631261",
      "Logopfad": ""
    },{
      "Id": 40,
      "Name": "Georg Theile",
      "Strasse": "Reichenberger Straße",
      "Hausnr": "11",
      "PLZ": "02763",
      "Ort": "Zittau",
      "Webseite": "https://georgtheile.de",
      "Telefonnummer": "03583 500970",
      "Mail": "info@georgtheile.de",
      "Logo": "",
      "Coordinates": "50.8956493, 14.8086816",
      "Logopfad": ""
    },{
      "Id": 41,
      "Name": "Gerlach Glas Porzellan ",
      "Strasse": "Silhöfer Straße",
      "Hausnr": "32",
      "PLZ": "35578",
      "Ort": "Wetzlar",
      "Webseite": "https://wp.porzellan-gerlach.de/",
      "Telefonnummer": "06441 946166",
      "Mail": "info@gerlach-wetzlar.de",
      "Logo": "",
      "Coordinates": "50.5532343, 8.5004231",
      "Logopfad": ""
    },{
      "Id": 42,
      "Name": "Geschenkhaus Hammer",
      "Strasse": "Hauptstraße",
      "Hausnr": "77",
      "PLZ": "76684",
      "Ort": "Oestringen",
      "Webseite": "https://www.geschenkhaus-hammer.de",
      "Telefonnummer": "07253 924176",
      "Mail": "geschenkhaus-hammer@t-online.de",
      "Logo": "",
      "Coordinates": "49.2183259, 8.7075018",
      "Logopfad": ""
    },{
      "Id": 43,
      "Name": "Geschenkhaus Reimer",
      "Strasse": "Victor-Kretz-Str.",
      "Hausnr": "32",
      "PLZ": "77723",
      "Ort": "Gengenbach",
      "Webseite": "https://www.reimer-gengenbach.de",
      "Telefonnummer": "07803 4265",
      "Mail": "info@reimer-gengenbach.de",
      "Logo": "",
      "Coordinates": "48.405128, 8.0160712",
      "Logopfad": ""
    },{
      "Id": 44,
      "Name": "Grimm",
      "Strasse": "Obere Hauptstr. ",
      "Hausnr": "17",
      "PLZ": "85354",
      "Ort": "Freising",
      "Webseite": "www.mein-grimm.de",
      "Telefonnummer": "08161 48450",
      "Mail": "freising@mein-grimm.de ",
      "Logo": "",
      "Coordinates": "48.3999002, 11.742688",
      "Logopfad": ""
    },{
      "Id": 45,
      "Name": "Gustav Kachel",
      "Strasse": "Marktstr.",
      "Hausnr": "9-11",
      "PLZ": "74523",
      "Ort": "Schwäbisch-Hall",
      "Webseite": "http://kachel-sha.de",
      "Telefonnummer": "0791 6233",
      "Mail": "info@kachel-sha.de ",
      "Logo": "",
      "Coordinates": "49.1134903, 9.7364294",
      "Logopfad": ""
    },{
      "Id": 46,
      "Name": "Gutermann zum Blumenstrauß",
      "Strasse": "Marktplatz ",
      "Hausnr": "4",
      "PLZ": "88400",
      "Ort": "Biberach",
      "Webseite": "www.gutermann-bc.de",
      "Telefonnummer": "07351 18340",
      "Mail": "hausrat@gutermann-bc.de",
      "Logo": "",
      "Coordinates": "48.0986807, 9.7885785",
      "Logopfad": ""
    },{
      "Id": 47,
      "Name": "H. Tetzner",
      "Strasse": "Breite Str.",
      "Hausnr": "60",
      "PLZ": "38855",
      "Ort": "Wernigerode",
      "Webseite": "https://www.h-tetzner.de/index.php",
      "Telefonnummer": "03943 63 30 04",
      "Mail": "info@h-tetzner.de",
      "Logo": "",
      "Coordinates": "51.835145, 10.7896706",
      "Logopfad": ""
    },{
      "Id": 48,
      "Name": "Haase",
      "Strasse": "Lienaustr.",
      "Hausnr": "10",
      "PLZ": "23730",
      "Ort": "Neustadt in Holstein",
      "Webseite": "https://haase-neustadt.de",
      "Telefonnummer": "04561 4402",
      "Mail": "info@haase-neustadt.de",
      "Logo": "",
      "Coordinates": "54.1063425, 10.8077884",
      "Logopfad": ""
    },{
      "Id": 49,
      "Name": "Hans Bütterhoff",
      "Strasse": "Mühlenstraße",
      "Hausnr": "11-13",
      "PLZ": "48703",
      "Ort": "Stadtlohn",
      "Webseite": "https://www.buetterhoff.de/",
      "Telefonnummer": "02563 933623",
      "Mail": "info@buetterhoff.de",
      "Logo": "",
      "Coordinates": "51.9937522, 6.916043",
      "Logopfad": ""
    },{
      "Id": 50,
      "Name": "Haushaltswaren Voges",
      "Strasse": "Osttor",
      "Hausnr": "5",
      "PLZ": "48324",
      "Ort": "Sendenhorst",
      "Webseite": "https://www.haushaltswaren-voges.de",
      "Telefonnummer": "02526 1406 ",
      "Mail": "info@haushaltswaren-voges.de",
      "Logo": "",
      "Coordinates": "51.8443338, 7.8305019",
      "Logopfad": ""
    },{
      "Id": 51,
      "Name": "Häussermann",
      "Strasse": "Marktstraße",
      "Hausnr": "27",
      "PLZ": "71364",
      "Ort": "Winnenden",
      "Webseite": "https://www.haeussermann-winnenden.de/",
      "Telefonnummer": "07195 174001",
      "Mail": "info@haeussermann-winnenden.de",
      "Logo": "",
      "Coordinates": "48.8767517, 9.3976006",
      "Logopfad": ""
    },{
      "Id": 52,
      "Name": "Hornschu",
      "Strasse": "Oberste Gasse ",
      "Hausnr": "3-7",
      "PLZ": "34117",
      "Ort": "Kassel",
      "Webseite": "https://www.hornschu.de",
      "Telefonnummer": "0561 772329",
      "Mail": "mail@hornschu-shop.de",
      "Logo": "",
      "Coordinates": "51.3144433, 9.498737",
      "Logopfad": ""
    },{
      "Id": 53,
      "Name": "INSTINCT",
      "Strasse": "Collegienstraße",
      "Hausnr": "48",
      "PLZ": "06886",
      "Ort": "Lutherstadt Wittenberg",
      "Webseite": "https://instinct-wb.de",
      "Telefonnummer": "03491 5057910",
      "Mail": "info@instinct-wb.de",
      "Logo": "",
      "Coordinates": "51.8649281, 12.6514163",
      "Logopfad": ""
    },{
      "Id": 54,
      "Name": "Jürgen Mayer",
      "Strasse": "Friedhofstr.",
      "Hausnr": "1",
      "PLZ": "73430",
      "Ort": "Aalen",
      "Webseite": "https://www.mayer-aalen.de",
      "Telefonnummer": "07361 6041",
      "Mail": "info@mayer-aalen.de",
      "Logo": "",
      "Coordinates": "48.8372422, 10.0916078",
      "Logopfad": ""
    },{
      "Id": 55,
      "Name": "Kate & Herr Henke",
      "Strasse": "Wasserstr.",
      "Hausnr": "25",
      "PLZ": "33378",
      "Ort": "Rheda-Wiedenbrück",
      "Webseite": "https://www.instagram.com/kate_und_herr_henke/",
      "Telefonnummer": "05242 5314",
      "Mail": "kateundherrhenke@gmail.com",
      "Logo": "",
      "Coordinates": "51.8336444, 8.3118187",
      "Logopfad": ""
    },{
      "Id": 56,
      "Name": "Kaufhaus Ahrens",
      "Strasse": "Universitätsstraße",
      "Hausnr": "14-22",
      "PLZ": "35037",
      "Ort": "Marburg",
      "Webseite": "https://www.ahrens-marburg.de/",
      "Telefonnummer": "06421 2980",
      "Mail": "info@ahrens-marburg.de",
      "Logo": "",
      "Coordinates": "50.8065033, 8.7672603",
      "Logopfad": ""
    },{
      "Id": 57,
      "Name": "Kaulfuß",
      "Strasse": "Muskauer Str.",
      "Hausnr": "79-81",
      "PLZ": "02943",
      "Ort": "Weißwasser",
      "Webseite": "https://www.kaulfuss-gartencenter.de",
      "Telefonnummer": "03576 218888",
      "Mail": "kaulfuss-gmbh@t-online.de",
      "Logo": "",
      "Coordinates": "51.5064291, 14.6428878",
      "Logopfad": ""
    },{
      "Id": 58,
      "Name": "Kicherer",
      "Strasse": "Schmiedstr.",
      "Hausnr": "5-7",
      "PLZ": "73479",
      "Ort": "Ellwangen",
      "Webseite": "https://www.kicherer-home.de/",
      "Telefonnummer": "07961 8850",
      "Mail": "info@kicherer-home.de",
      "Logo": "",
      "Coordinates": "48.9618308, 10.1304277",
      "Logopfad": ""
    },{
      "Id": 59,
      "Name": "Kobalz",
      "Strasse": "Kamenzer Straße",
      "Hausnr": "4",
      "PLZ": "02997",
      "Ort": "Wittichenau",
      "Webseite": "https://kobalz.de/",
      "Telefonnummer": "035725 79826",
      "Mail": "info@kobalz.de",
      "Logo": "",
      "Coordinates": "51.3850828, 14.2429836",
      "Logopfad": ""
    },{
      "Id": 60,
      "Name": "KOCH Laden",
      "Strasse": "Bahnhofstr.",
      "Hausnr": "25",
      "PLZ": "32257",
      "Ort": "Bünde",
      "Webseite": "https://kochladen-buende.de",
      "Telefonnummer": "05223 1895406",
      "Mail": "info@kochladen-buende.de",
      "Logo": "",
      "Coordinates": "52.19785, 8.57924",
      "Logopfad": ""
    },{
      "Id": 61,
      "Name": "KochForm GmbH",
      "Strasse": "Pötzschker Weg",
      "Hausnr": "8",
      "PLZ": "04179",
      "Ort": "Leipzig",
      "Webseite": "www.kochform.de",
      "Telefonnummer": "0341 3929280",
      "Mail": "info@kochform.de",
      "Logo": "",
      "Coordinates": "51.3263373, 12.3102317",
      "Logopfad": ""
    },{
      "Id": 62,
      "Name": "Kochshop Bergheim",
      "Strasse": "Hauptstr.",
      "Hausnr": "74",
      "PLZ": "50126",
      "Ort": "Bergheim",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "02271 7594040",
      "Mail": "bergheim@kochshop.eu",
      "Logo": "",
      "Coordinates": "50.9533492, 6.6374894",
      "Logopfad": ""
    },{
      "Id": 63,
      "Name": "Kochshop Darmstadt",
      "Strasse": "Wilhelminenstr.",
      "Hausnr": "21",
      "PLZ": "64283",
      "Ort": "Darmstadt",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "06151 25030",
      "Mail": "darmstadt@kochshop.eu",
      "Logo": "",
      "Coordinates": "49.8704941, 8.6512401",
      "Logopfad": ""
    },{
      "Id": 64,
      "Name": "Kochshop Düren",
      "Strasse": "Hirschgasse",
      "Hausnr": "2-4",
      "PLZ": "52349",
      "Ort": "Düren",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "02421 5916492",
      "Mail": "dueren@kochshop.eu",
      "Logo": "",
      "Coordinates": "50.8031501, 6.4842207",
      "Logopfad": ""
    },{
      "Id": 65,
      "Name": "Kochshop Heinsberg",
      "Strasse": "Hochstr.",
      "Hausnr": "52",
      "PLZ": "52525",
      "Ort": "Heinsberg",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "02452 22052",
      "Mail": "heinsberg@kochshop.eu",
      "Logo": "",
      "Coordinates": "51.06115, 6.09356",
      "Logopfad": ""
    },{
      "Id": 66,
      "Name": "Kochshop Hilden",
      "Strasse": "Schulstr.",
      "Hausnr": "10",
      "PLZ": "40721",
      "Ort": "Hilden",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "02103 9762943",
      "Mail": "hilden@kochshop.eu",
      "Logo": "",
      "Coordinates": "51.16759, 6.93276",
      "Logopfad": ""
    },{
      "Id": 67,
      "Name": "Kochshop Langenfeld",
      "Strasse": "Hauptstr.",
      "Hausnr": "120",
      "PLZ": "40764",
      "Ort": "Langenfeld (Rheinland)",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "02173 9633991",
      "Mail": "langenfeld@kochshop.eu",
      "Logo": "",
      "Coordinates": "51.10842, 6.9491291",
      "Logopfad": ""
    },{
      "Id": 68,
      "Name": "Kochshop Mönchengladbach-Rheydt",
      "Strasse": "Hauptstr.",
      "Hausnr": "27",
      "PLZ": "41236",
      "Ort": "Mönchengladbach-Rheydt",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "02166 1450047",
      "Mail": "moenchengladbach@kochshop.eu",
      "Logo": "",
      "Coordinates": "51.167827, 6.4422515",
      "Logopfad": ""
    },{
      "Id": 69,
      "Name": "Kochshop Ratingen",
      "Strasse": "Bahnstr.",
      "Hausnr": "8",
      "PLZ": "40878",
      "Ort": "Ratingen",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "02102 1337839",
      "Mail": "ratingen@kochshop.eu",
      "Logo": "",
      "Coordinates": "51.298414, 6.8543893",
      "Logopfad": ""
    },{
      "Id": 70,
      "Name": "Kochshop Solingen",
      "Strasse": "Höhscheider Weg",
      "Hausnr": "29",
      "PLZ": "42699",
      "Ort": "Solingen",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "0212 6582800",
      "Mail": "solingen@kochshop.eu",
      "Logo": "",
      "Coordinates": "51.1505278, 7.0290391",
      "Logopfad": ""
    },{
      "Id": 71,
      "Name": "Kochshop Wuppertal",
      "Strasse": "Herzogstr.",
      "Hausnr": "28",
      "PLZ": "42103",
      "Ort": "Wuppertal",
      "Webseite": "https://www.kochshop.eu",
      "Telefonnummer": "0202 47828795",
      "Mail": "wuppertal@kochshop.eu",
      "Logo": "",
      "Coordinates": "51.2573301, 7.1435838",
      "Logopfad": ""
    },{
      "Id": 72,
      "Name": "KÖHLER Fachhandel",
      "Strasse": "Große Kirchgasse",
      "Hausnr": "18",
      "PLZ": "09456",
      "Ort": "Annaberg-Buchholz",
      "Webseite": "https://www.haushaltstraum.de/",
      "Telefonnummer": "03733 23256",
      "Mail": "koehler.fachhandel@t-online.de",
      "Logo": "",
      "Coordinates": "50.5779362, 13.0046446",
      "Logopfad": ""
    },{
      "Id": 73,
      "Name": "Konrad Kunz",
      "Strasse": "Waldprechtsstr.",
      "Hausnr": "4",
      "PLZ": "76316",
      "Ort": "Malsch",
      "Webseite": "https://ig-malsch.de/item/konrad-kunz-eisenwaren/",
      "Telefonnummer": "07246 1349",
      "Mail": "konrad.kunz.malsch@t-online.de",
      "Logo": "",
      "Coordinates": "48.880216, 8.3344791",
      "Logopfad": ""
    },{
      "Id": 74,
      "Name": "Kotters",
      "Strasse": "Kavarinerstr.",
      "Hausnr": "14-16",
      "PLZ": "47533",
      "Ort": "Kleve",
      "Webseite": "https://kotters.de",
      "Telefonnummer": "02821 22958",
      "Mail": "vertrieb@kotters.de",
      "Logo": "",
      "Coordinates": "51.7893233, 6.1387924",
      "Logopfad": ""
    },{
      "Id": 75,
      "Name": "Kratt",
      "Strasse": "Marktplatz",
      "Hausnr": "13",
      "PLZ": "78315",
      "Ort": "Radolfzell",
      "Webseite": "https://www.kratt-radolfzell.de/",
      "Telefonnummer": "07732 92660",
      "Mail": "info@kratt-radolfzell.de",
      "Logo": "",
      "Coordinates": "47.7374747, 8.970634",
      "Logopfad": ""
    },{
      "Id": 76,
      "Name": "Küchenladen",
      "Strasse": "Knesebeckstraße",
      "Hausnr": "26",
      "PLZ": "10623",
      "Ort": "Berlin",
      "Webseite": "https://www.kuechenladen.com/",
      "Telefonnummer": "030 8813908",
      "Mail": "info@kuechenladen.com",
      "Logo": "",
      "Coordinates": "52.5045855, 13.3218495",
      "Logopfad": ""
    },{
      "Id": 77,
      "Name": "Kurz Elektro-Zentrum",
      "Strasse": "Robert-Bosch-Str. ",
      "Hausnr": "38-46",
      "PLZ": "72250",
      "Ort": "Freudenstadt",
      "Webseite": "https://kurz.elektro-online.de/home.html",
      "Telefonnummer": "07441 5370",
      "Mail": "info@kurz-elektro-zentrum.de",
      "Logo": "",
      "Coordinates": "48.4638, 8.4347",
      "Logopfad": ""
    },{
      "Id": 78,
      "Name": "La Casa",
      "Strasse": "Kaiserstraße",
      "Hausnr": "53",
      "PLZ": "52146",
      "Ort": "Würselen",
      "Webseite": "https://www.haushaltswarenwuerselen.de/",
      "Telefonnummer": "02405 4794242",
      "Mail": "lacasawuerselen@gmx.de",
      "Logo": "",
      "Coordinates": "50.8205549, 6.1308894",
      "Logopfad": ""
    },{
      "Id": 79,
      "Name": "Laegel Im Kaufmannshaus",
      "Strasse": "Bleichenbrücke",
      "Hausnr": "10",
      "PLZ": "20354",
      "Ort": "Hamburg",
      "Webseite": "https://www.laegel.net/",
      "Telefonnummer": "040 37503866",
      "Mail": "info@laegel.net",
      "Logo": "",
      "Coordinates": "53.5521092, 9.9886694",
      "Logopfad": ""
    },{
      "Id": 80,
      "Name": "Lambert Schmidt",
      "Strasse": "Hauptstraße",
      "Hausnr": "17",
      "PLZ": "79837",
      "Ort": "St. Blasien",
      "Webseite": "https://lambert-schmidt.de/",
      "Telefonnummer": "07672 514",
      "Mail": "info@lambert-schmidt.de",
      "Logo": "",
      "Coordinates": "47.7614351, 8.1298118",
      "Logopfad": ""
    },{
      "Id": 81,
      "Name": "Leifeld Kochen und Küche",
      "Strasse": "Horster Str.",
      "Hausnr": "23-25",
      "PLZ": "45897",
      "Ort": "Gelsenkirchen-Buer",
      "Webseite": "https://www.leifeld-buer.de",
      "Telefonnummer": "0209 30339",
      "Mail": "info@leifeld-buer.de",
      "Logo": "",
      "Coordinates": "51.5765803, 7.0521754",
      "Logopfad": ""
    },{
      "Id": 82,
      "Name": "Leonardo Geschenkboutique",
      "Strasse": "Lange Straße",
      "Hausnr": "50",
      "PLZ": "17489",
      "Ort": "Greifswald",
      "Webseite": "https://www.leonardogeschenkboutique.de/",
      "Telefonnummer": "03834 894961",
      "Mail": "leonardoboutiquehgw@gmail.com",
      "Logo": "",
      "Coordinates": "54.0961253, 13.3765805",
      "Logopfad": ""
    },{
      "Id": 83,
      "Name": "Lich am Marktplatz",
      "Strasse": "Marktstraße",
      "Hausnr": "7",
      "PLZ": "35390",
      "Ort": "Gießen",
      "Webseite": "https://www.lich-elektrofachhaus.de/hausgeraete/startseite",
      "Telefonnummer": "0641 35031",
      "Mail": "Lich.Elektrofachhaus@gmx.de",
      "Logo": "",
      "Coordinates": "50.5858643, 8.6734536",
      "Logopfad": ""
    },{
      "Id": 84,
      "Name": "Löffler Haushalt und Geschenke",
      "Strasse": "Hauptstraße",
      "Hausnr": "39",
      "PLZ": "79346",
      "Ort": "Endingen",
      "Webseite": "https://haushaltwaren-loeffler.de/",
      "Telefonnummer": "07642 8059",
      "Mail": "haushaltwaren-loeffler@gmx.de",
      "Logo": "",
      "Coordinates": "48.1416966, 7.7042212",
      "Logopfad": ""
    },{
      "Id": 85,
      "Name": "Ludwig Eppler KG",
      "Strasse": "Sonnenstraße",
      "Hausnr": "3",
      "PLZ": "72458",
      "Ort": "Albstadt-Ebingen",
      "Webseite": "https://www.ludwig-eppler.de/",
      "Telefonnummer": "07431 90000",
      "Mail": "info@ludwig-eppler.de",
      "Logo": "",
      "Coordinates": "48.2126432, 9.0234034",
      "Logopfad": ""
    },{
      "Id": 86,
      "Name": "Margot Banzhaf",
      "Strasse": "Osterstr.",
      "Hausnr": "1",
      "PLZ": "89547",
      "Ort": "Gerstetten",
      "Webseite": "https://www.margot-banzhaf.de",
      "Telefonnummer": "07323 4770",
      "Mail": "margot.banzhaf@t-online.de",
      "Logo": "",
      "Coordinates": "48.6238613, 10.0173685",
      "Logopfad": ""
    },{
      "Id": 87,
      "Name": "Messer Maurer",
      "Strasse": "Fleiner Straße",
      "Hausnr": "24",
      "PLZ": "74072",
      "Ort": "Heilbronn",
      "Webseite": "https://www.messer-maurer.de/",
      "Telefonnummer": "07131 82577",
      "Mail": "info@messer-maurer.de",
      "Logo": "",
      "Coordinates": "49.1405024, 9.2189833",
      "Logopfad": ""
    },{
      "Id": 88,
      "Name": "Messer-Mager",
      "Strasse": "Alaunstraße",
      "Hausnr": "10",
      "PLZ": "01099",
      "Ort": "Dresden",
      "Webseite": "https://messer-mager.de/messer-dresden/",
      "Telefonnummer": "0351 8031803",
      "Mail": "info@messer-mager.de",
      "Logo": "",
      "Coordinates": "51.0642163, 13.7490866",
      "Logopfad": ""
    },{
      "Id": 89,
      "Name": "Messerschmied Hermann",
      "Strasse": "Stadtgrabenstraße",
      "Hausnr": "11",
      "PLZ": "71032",
      "Ort": "Böblingen",
      "Webseite": "https://messerschmied-hermann.de",
      "Telefonnummer": "07031 227393",
      "Mail": "info@messerschmied-hermann.de",
      "Logo": "",
      "Coordinates": "48.6860428, 9.0104785",
      "Logopfad": ""
    },{
      "Id": 90,
      "Name": "Messerschmied Reisch",
      "Strasse": "Kornhausstraße",
      "Hausnr": "10",
      "PLZ": "88299",
      "Ort": "Leutkirch im Allgäu",
      "Webseite": "",
      "Telefonnummer": "07561 3707",
      "Mail": "Thomas.reisch@web.de",
      "Logo": "",
      "Coordinates": "47.8268379, 10.0228896",
      "Logopfad": ""
    },{
      "Id": 91,
      "Name": "Messerschmiede Kneissler",
      "Strasse": "Hauptstraße",
      "Hausnr": "75",
      "PLZ": "77736",
      "Ort": "Zell am Harmersbach",
      "Webseite": "https://www.kneissler-messer.de/",
      "Telefonnummer": "07835 8550",
      "Mail": "info@kneissler-messer.de",
      "Logo": "",
      "Coordinates": "48.3475273, 8.0690046",
      "Logopfad": ""
    },{
      "Id": 92,
      "Name": "Nessler ",
      "Strasse": "Hamburger Str.",
      "Hausnr": "4-8",
      "PLZ": "22926",
      "Ort": "Ahrensburg",
      "Webseite": "https://www.nessler.de/einkaufswelt/standorte/ahrensburg",
      "Telefonnummer": "04102 890109",
      "Mail": "info@nessler.de",
      "Logo": "",
      "Coordinates": "53.6727362, 10.2375918",
      "Logopfad": ""
    },{
      "Id": 93,
      "Name": "Niehaus",
      "Strasse": "Plettenberger Str.",
      "Hausnr": "5",
      "PLZ": "49324",
      "Ort": "Melle",
      "Webseite": "http://www.niehaus-melle.de",
      "Telefonnummer": "05422 1400",
      "Mail": "hausrat@niehaus-melle.de ",
      "Logo": "",
      "Coordinates": "52.2014164, 8.3359153",
      "Logopfad": ""
    },{
      "Id": 94,
      "Name": "Ortgies Haus & Co",
      "Strasse": "Gerichtsstraße",
      "Hausnr": "9",
      "PLZ": "32369",
      "Ort": "Rahden",
      "Webseite": "https://ortgies-rahden.de/",
      "Telefonnummer": "05771 97330",
      "Mail": "info@ortgies-rahden.de",
      "Logo": "",
      "Coordinates": "52.4327013, 8.6155853",
      "Logopfad": ""
    },{
      "Id": 95,
      "Name": "Osche",
      "Strasse": "Baseler Straße",
      "Hausnr": "9",
      "PLZ": "12205",
      "Ort": "Berlin",
      "Webseite": "https://www.alfred-osche.de/",
      "Telefonnummer": "030 8331900",
      "Mail": "Laden@Alfred-Osche.de",
      "Logo": "",
      "Coordinates": "52.4422904, 13.2960474",
      "Logopfad": ""
    },{
      "Id": 96,
      "Name": "Ott",
      "Strasse": "Wassertorstr.",
      "Hausnr": "11",
      "PLZ": "88316",
      "Ort": "Isny",
      "Webseite": "https://ott-isny.de/",
      "Telefonnummer": "07562 4580",
      "Mail": "ott-isny@t-online.de",
      "Logo": "",
      "Coordinates": "47.69287, 10.03959",
      "Logopfad": ""
    },{
      "Id": 97,
      "Name": "Otto Asal",
      "Strasse": "Meinrad-Thoma-Straße",
      "Hausnr": "15",
      "PLZ": "79674",
      "Ort": "Todtnau",
      "Webseite": "https://www.ottoasal.de",
      "Telefonnummer": "07671 435",
      "Mail": "info@ottoasal.de",
      "Logo": "",
      "Coordinates": "47.8298427, 7.9451008",
      "Logopfad": ""
    },{
      "Id": 98,
      "Name": "Paul Leinen Haushaltwaren",
      "Strasse": "Großer Markt",
      "Hausnr": "14",
      "PLZ": "66740",
      "Ort": "Saarlouis",
      "Webseite": "https://www.paul-leinen-haushalt.de/",
      "Telefonnummer": "06831 2218",
      "Mail": "paul.leinen.team@web.de",
      "Logo": "",
      "Coordinates": "49.3149848, 6.7515258",
      "Logopfad": ""
    },{
      "Id": 99,
      "Name": "Porzellangalerie Jahn",
      "Strasse": "Rathausstr.",
      "Hausnr": "6",
      "PLZ": "92224",
      "Ort": "Amberg",
      "Webseite": "https://www.porzellangalerie.de/",
      "Telefonnummer": "09621 33307",
      "Mail": "info@porzellangalerie.de",
      "Logo": "",
      "Coordinates": "49.4452396, 11.8592539",
      "Logopfad": ""
    },{
      "Id": 100,
      "Name": "Porzellanhaus Mebold",
      "Strasse": "Friedrichstr.",
      "Hausnr": "62",
      "PLZ": "72336",
      "Ort": "Balingen",
      "Webseite": "http://www.porzellanhaus-mebold.de",
      "Telefonnummer": "07433 7518",
      "Mail": "mebold@porzellanhaus-mebold.de",
      "Logo": "",
      "Coordinates": "48.27218, 8.8504638",
      "Logopfad": ""
    },{
      "Id": 101,
      "Name": "Rackl am Rathaus",
      "Strasse": "Hallertorstraße",
      "Hausnr": "1",
      "PLZ": "92318",
      "Ort": "Neumarkt",
      "Webseite": "https://www.facebook.com/RacklamRathaus/?locale=de_DE",
      "Telefonnummer": "09181 26710",
      "Mail": "info@rackl-haushaltswaren.com",
      "Logo": "",
      "Coordinates": "49.2798551, 11.4582578",
      "Logopfad": ""
    },{
      "Id": 102,
      "Name": "Ralf Bauersachs",
      "Strasse": "Metelener Straße",
      "Hausnr": "7",
      "PLZ": "48493",
      "Ort": "Wettringen",
      "Webseite": "https://www.bauersachs-wettringen.de/",
      "Telefonnummer": "02557 230",
      "Mail": "info@bauersachs-wettringen.de",
      "Logo": "",
      "Coordinates": "52.208868, 7.3155604",
      "Logopfad": ""
    },{
      "Id": 103,
      "Name": "Reinig",
      "Strasse": "Bahnhofstr.",
      "Hausnr": "15",
      "PLZ": "69412",
      "Ort": "Eberbach",
      "Webseite": "https://www.reinig-eberbach.de",
      "Telefonnummer": "06271-92660",
      "Mail": "info@reinig-eberbach.de",
      "Logo": "",
      "Coordinates": "49.4632772, 8.9861918",
      "Logopfad": ""
    },{
      "Id": 104,
      "Name": "Renschler",
      "Strasse": "Tübinger Straße ",
      "Hausnr": "18",
      "PLZ": "71083",
      "Ort": "Herrenberg",
      "Webseite": "https://www.renschler-herrenberg.de",
      "Telefonnummer": "07032 941060",
      "Mail": "info@renschler-herrenberg.de",
      "Logo": "",
      "Coordinates": "48.5953788, 8.8711026",
      "Logopfad": ""
    },{
      "Id": 105,
      "Name": "Restle Haushaltswaren",
      "Strasse": "Hauptstraße",
      "Hausnr": "17",
      "PLZ": "88074",
      "Ort": "Meckenbeuren",
      "Webseite": "https://www.restle-meckenbeuren.de",
      "Telefonnummer": "07542 406873 ",
      "Mail": "simone.restle@gmx.de",
      "Logo": "",
      "Coordinates": "47.699275, 9.5631198",
      "Logopfad": ""
    },{
      "Id": 106,
      "Name": "Rex Haushaltwaren",
      "Strasse": "Hauptstraße",
      "Hausnr": "24",
      "PLZ": "72280",
      "Ort": "Dornstetten",
      "Webseite": "https://www.instagram.com/haushaltswarenrex/?hl=de",
      "Telefonnummer": "07443 3550",
      "Mail": "rex.haushalt@t-online.de",
      "Logo": "",
      "Coordinates": "48.468202, 8.500458",
      "Logopfad": ""
    },{
      "Id": 107,
      "Name": "Schatz am Weinmarkt",
      "Strasse": "Hallgasse",
      "Hausnr": "19",
      "PLZ": "86720",
      "Ort": "Nördlingen",
      "Webseite": "https://www.schatz-haushaltswaren.de/",
      "Telefonnummer": "09081 2723232",
      "Mail": "info@schatz-haushaltswaren.de",
      "Logo": "",
      "Coordinates": "48.8500475, 10.4870332",
      "Logopfad": ""
    },{
      "Id": 108,
      "Name": "Schatzkästchen",
      "Strasse": "Markt",
      "Hausnr": "8",
      "PLZ": "06484",
      "Ort": "Quedlinburg ",
      "Webseite": "",
      "Telefonnummer": "03946 524217",
      "Mail": "schatzkaestchen_qlb@gmx.de",
      "Logo": "",
      "Coordinates": "51.7888143, 11.1416372",
      "Logopfad": ""
    },{
      "Id": 109,
      "Name": "Scheffold",
      "Strasse": "Hauptstraße",
      "Hausnr": "32",
      "PLZ": "88339",
      "Ort": "Bad Waldsee",
      "Webseite": "https://tischkultur.albert-scheffold.de",
      "Telefonnummer": "07524 8086",
      "Mail": "shop@albert-scheffold.de",
      "Logo": "",
      "Coordinates": "47.9209237, 9.7548084",
      "Logopfad": ""
    },{
      "Id": 110,
      "Name": "Schinle",
      "Strasse": "Oberndorfer Straße",
      "Hausnr": "2",
      "PLZ": "78713",
      "Ort": "Schramberg",
      "Webseite": "https://www.schinle-online.de/",
      "Telefonnummer": "07422 23153",
      "Mail": "info@schinle-online.de",
      "Logo": "",
      "Coordinates": "48.2249541, 8.3857809",
      "Logopfad": ""
    },{
      "Id": 111,
      "Name": "Schlee Küche und Idee",
      "Strasse": "Talweg",
      "Hausnr": "4",
      "PLZ": "72285",
      "Ort": "Herzogsweiler",
      "Webseite": "https://www.schlee-kuechen.de/",
      "Telefonnummer": "07445 6853",
      "Mail": "info@schlee-kuechen.de",
      "Logo": "",
      "Coordinates": "48.507856, 8.536299",
      "Logopfad": ""
    },{
      "Id": 112,
      "Name": "Schreiner",
      "Strasse": "Salzburger Gasse",
      "Hausnr": "2",
      "PLZ": "93047",
      "Ort": "Regensburg",
      "Webseite": "https://www.schreiner-regensburg.de/",
      "Telefonnummer": "0941 58001",
      "Mail": "info@schreiner-regensburg.de",
      "Logo": "",
      "Coordinates": "49.0180995, 12.0987695",
      "Logopfad": ""
    },{
      "Id": 113,
      "Name": "Schreiner e.K.",
      "Strasse": "Friedrich-Ebert-Str.",
      "Hausnr": "3",
      "PLZ": "92421",
      "Ort": "Schwandorf",
      "Webseite": "https://www.haushaltswaren-schreiner.de",
      "Telefonnummer": "094 312277",
      "Mail": "schreiner.haushalt@t-online.de",
      "Logo": "",
      "Coordinates": "49.3294492, 12.1072406",
      "Logopfad": ""
    },{
      "Id": 114,
      "Name": "Schwager",
      "Strasse": "Johannisplatz",
      "Hausnr": "1",
      "PLZ": "99817",
      "Ort": "Eisenach",
      "Webseite": "https://www.schwager.de/eisenach/",
      "Telefonnummer": "03691 29030",
      "Mail": "kundenservice@schwager.de",
      "Logo": "",
      "Coordinates": "50.9739033, 10.3240416",
      "Logopfad": ""
    },{
      "Id": 115,
      "Name": "Sexauer Ihr Fachgeschäft",
      "Strasse": "Hauptstr.",
      "Hausnr": "6",
      "PLZ": "79268",
      "Ort": "Bötzingen",
      "Webseite": "https://www.sexauer-boetzingen.de",
      "Telefonnummer": "076631521",
      "Mail": "kontakt@sexauer-boetzingen.de",
      "Logo": "",
      "Coordinates": "48.0719545, 7.7198829",
      "Logopfad": ""
    },{
      "Id": 116,
      "Name": "Seyock Elektro-Haustechnik",
      "Strasse": "Große Weilstr.",
      "Hausnr": "12",
      "PLZ": "45525",
      "Ort": "Hattingen",
      "Webseite": "https://www.elektroseyock.de/",
      "Telefonnummer": "02324 21387",
      "Mail": "info@elektroseyock.de",
      "Logo": "",
      "Coordinates": "51.3984511, 7.1819755",
      "Logopfad": ""
    },{
      "Id": 117,
      "Name": "Stahlwaren Lützenkirchen",
      "Strasse": "Hafergasse",
      "Hausnr": "7",
      "PLZ": "67547",
      "Ort": "Worms",
      "Webseite": "http://luetzenkirchen1896.de/",
      "Telefonnummer": "06241 25164",
      "Mail": "ghofmeister@web.de",
      "Logo": "",
      "Coordinates": "49.6313773, 8.3614523",
      "Logopfad": ""
    },{
      "Id": 118,
      "Name": "Stahlwaren Wagner",
      "Strasse": "Weststraße",
      "Hausnr": "7",
      "PLZ": "87561",
      "Ort": "Oberstdorf",
      "Webseite": "https://www.stahlwaren-wagner.de/",
      "Telefonnummer": "08322 98500",
      "Mail": "info@stahlwaren-wagner.de",
      "Logo": "",
      "Coordinates": "47.4081114, 10.2768009",
      "Logopfad": ""
    },{
      "Id": 119,
      "Name": "Stil und Ambiente",
      "Strasse": "Kaiserstraße",
      "Hausnr": "89",
      "PLZ": "76761",
      "Ort": "Waldshut",
      "Webseite": "https://stilundambiente.de/",
      "Telefonnummer": "07751 3552",
      "Mail": "kontakt@stilundambiente.de",
      "Logo": "",
      "Coordinates": "47.6327253, 8.2719155",
      "Logopfad": ""
    },{
      "Id": 120,
      "Name": "Surger Das Fachgeschäft",
      "Strasse": "Lange Straße",
      "Hausnr": "16",
      "PLZ": "27404",
      "Ort": "Zeven",
      "Webseite": "https://www.surger.de",
      "Telefonnummer": "04281 2573",
      "Mail": "eisenwaren@surger.de",
      "Logo": "",
      "Coordinates": "53.2937297, 9.2776824",
      "Logopfad": ""
    },{
      "Id": 121,
      "Name": "Tensi",
      "Strasse": "Ostheimer Straße",
      "Hausnr": "9",
      "PLZ": "33034",
      "Ort": "Brakel",
      "Webseite": "https://www.tensi-gmbh.de",
      "Telefonnummer": "05272 5452",
      "Mail": "info@tensi-gmbh.de",
      "Logo": "",
      "Coordinates": "51.716395, 9.1853407",
      "Logopfad": ""
    },{
      "Id": 122,
      "Name": "Thommel Hausrat",
      "Strasse": "Bleicherstraße ",
      "Hausnr": "34",
      "PLZ": "88212",
      "Ort": "Ravensburg",
      "Webseite": "https://www.thommel-hausrat.de",
      "Telefonnummer": "0751 3638732",
      "Mail": "info@thommel-hausrat.de",
      "Logo": "",
      "Coordinates": "47.7935205, 9.6123911",
      "Logopfad": ""
    },{
      "Id": 123,
      "Name": "Topf und Pfanne Mannheim",
      "Strasse": "Q2,12",
      "Hausnr": "",
      "PLZ": "68161",
      "Ort": "Mannheim",
      "Webseite": "https://www.topfundpfanne.net",
      "Telefonnummer": "0621 104759",
      "Mail": "willkommen@topfundpfanne.de ",
      "Logo": "",
      "Coordinates": "49.4885245, 8.4687216",
      "Logopfad": ""
    },{
      "Id": 124,
      "Name": "TREND by emils",
      "Strasse": "Am Festplatz",
      "Hausnr": "2",
      "PLZ": "90562",
      "Ort": "Heroldsberg",
      "Webseite": "https://www.die-kleine-kochschule.de/index.php",
      "Telefonnummer": "0911 9569699",
      "Mail": "trend_diekleinekochschule@yahoo.com",
      "Logo": "",
      "Coordinates": "49.5329114, 11.153033",
      "Logopfad": ""
    },{
      "Id": 125,
      "Name": "Ulmer",
      "Strasse": "Hauptstr.",
      "Hausnr": "40-42",
      "PLZ": "79336",
      "Ort": "Herbolzheim",
      "Webseite": "https://ulmer.shop",
      "Telefonnummer": "07643 370",
      "Mail": "info@ulmer-shop.de",
      "Logo": "",
      "Coordinates": "48.2218779, 7.7771636",
      "Logopfad": ""
    },{
      "Id": 126,
      "Name": "Wenk Tischkultur",
      "Strasse": "Marktplatz",
      "Hausnr": "36",
      "PLZ": "94419",
      "Ort": "Reisbach",
      "Webseite": "https://www.wenk-tischkultur.de/",
      "Telefonnummer": "08734 93930",
      "Mail": "info@wenk-tischkultur.de",
      "Logo": "",
      "Coordinates": "48.57142, 12.62793",
      "Logopfad": ""
    },{
      "Id": 127,
      "Name": "Westerkamp",
      "Strasse": "Leverner Straße",
      "Hausnr": "24",
      "PLZ": "32351",
      "Ort": "Stemwede-Levern",
      "Webseite": "https://www.westerkamp-levern.de",
      "Telefonnummer": "05745 551",
      "Mail": "info@westerkamp-levern.de ",
      "Logo": "",
      "Coordinates": "52.3785537, 8.4492091",
      "Logopfad": ""
    },{
      "Id": 128,
      "Name": "Wilhelm Link",
      "Strasse": "Freihofstraße",
      "Hausnr": "1",
      "PLZ": "63500",
      "Ort": "Seligenstadt",
      "Webseite": "https://www.link-seligenstadt.de",
      "Telefonnummer": "06182 3301",
      "Mail": "info@link-seligenstadt.de",
      "Logo": "",
      "Coordinates": "50.0432138, 8.9757963",
      "Logopfad": ""
    },{
      "Id": 129,
      "Name": "Wilkens Werksverkauf",
      "Strasse": "Hemelinger Bahnhofstraße",
      "Hausnr": "21",
      "PLZ": "28309",
      "Ort": "Bremen",
      "Webseite": "https://www.wilkens-silber.de/werksverkauf-wilkens-bremen",
      "Telefonnummer": "0421 4103204",
      "Mail": "werksverkauf@wilkensundsoehne.com",
      "Logo": "",
      "Coordinates": "53.0597031, 8.8864183",
      "Logopfad": ""
    },{
      "Id": 130,
      "Name": "Wohnen & Kochen",
      "Strasse": "Maternusplatz ",
      "Hausnr": "6",
      "PLZ": "50996",
      "Ort": "Köln-Rodenkirchen",
      "Webseite": "http://wuk-koeln.de",
      "Telefonnummer": "0221 9231283",
      "Mail": "info@wuk-koeln.de",
      "Logo": "",
      "Coordinates": "50.8923927, 6.9943371",
      "Logopfad": ""
    },{
      "Id": 131,
      "Name": "Zerweck",
      "Strasse": "Cannstatter Str.",
      "Hausnr": "6",
      "PLZ": "70734",
      "Ort": "Fellbach ",
      "Webseite": "https://www.zerweck.com",
      "Telefonnummer": "0711 574011",
      "Mail": "Info@zerweck.com",
      "Logo": "",
      "Coordinates": "48.8071778, 9.2774441",
      "Logopfad": ""
    },{
      "Id": 132,
      "Name": "Zierleyn",
      "Strasse": "Am Schweinemarkt",
      "Hausnr": "3",
      "PLZ": "48529",
      "Ort": "Nordhorn",
      "Webseite": "https://www.zierleyn.de/",
      "Telefonnummer": "05921 83400 ",
      "Mail": "nils.holm@zierleyn.de",
      "Logo": "",
      "Coordinates": "52.4383384, 7.0686554",
      "Logopfad": ""
    },{
      "Id": 133,
      "Name": "Tischkultur Baxmann",
      "Strasse": "Berliner Straße",
      "Hausnr": "5-7",
      "PLZ": "33330",
      "Ort": "Gütersloh",
      "Webseite": "https://www.baxmann-gt.de/",
      "Telefonnummer": "0524 129168",
      "Mail": "rbaxmann88@aol.com",
      "Logo": "",
      "Coordinates": "51.9049604, 8.3770204",
      "Logopfad": ""
    },{
      "Id": 134,
      "Name": "Bernhard Klumpe",
      "Strasse": "Hauptstraße",
      "Hausnr": "6",
      "PLZ": "49757",
      "Ort": "Werlte",
      "Webseite": "https://www.bernhard-klumpe.de/",
      "Telefonnummer": "05951 3331",
      "Mail": "info@bernhard-klumpe.de",
      "Logo": "",
      "Coordinates": "52.850764, 7.6737718",
      "Logopfad": ""
    },{
      "Id": 135,
      "Name": "JOSEF",
      "Strasse": "Herrenstraße",
      "Hausnr": "24",
      "PLZ": "86911",
      "Ort": "Diessen am Ammersee",
      "Webseite": "https://josef.shop/",
      "Telefonnummer": "08807 335",
      "Mail": "info@josef.shop",
      "Logo": "",
      "Coordinates": "47.9479495, 11.0996322",
      "Logopfad": ""
    },{
      "Id": 136,
      "Name": "Möbelhaus Henrich",
      "Strasse": "Chauvignystraße",
      "Hausnr": "1",
      "PLZ": "65366",
      "Ort": "Geisenheim / Rheingau",
      "Webseite": "https://www.moebel-henrich.de/",
      "Telefonnummer": "06722 96000",
      "Mail": "info@moebel-henrich.de",
      "Logo": "",
      "Coordinates": "49.9847355, 7.9736661",
      "Logopfad": ""
    },{
      "Id": 137,
      "Name": "Kotte am Markt",
      "Strasse": "Unnaer Str.",
      "Hausnr": "7",
      "PLZ": "58636",
      "Ort": "Iserlohn",
      "Webseite": "https://www.kotte-tischkultur.de/",
      "Telefonnummer": "02371 931340",
      "Mail": "info@kotte-tischkultur.de",
      "Logo": "",
      "Coordinates": "51.3750881, 7.6974161",
      "Logopfad": ""
    },{
      "Id": 138,
      "Name": "Pasch Cooking Dining Living",
      "Strasse": "Vleesstraat",
      "Hausnr": "8",
      "PLZ": "5911 JE",
      "Ort": "Venlo",
      "Webseite": "https://webshop.pasch.nl/",
      "Telefonnummer": "077 3514542",
      "Mail": "pasch@pasch.nl",
      "Logo": "",
      "Coordinates": "51.369723, 6.1688181",
      "Logopfad": ""
    },{
      "Id": 139,
      "Name": "Van Genderen",
      "Strasse": "Voorstraat",
      "Hausnr": "65",
      "PLZ": "3201 BA",
      "Ort": "Spijkenisse",
      "Webseite": "https://www.vangenderenonline.nl",
      "Telefonnummer": "0181 612777",
      "Mail": "info@vgenderen.nl",
      "Logo": "",
      "Coordinates": "51.8494309, 4.3261118",
      "Logopfad": ""
    },{
      "Id": 140,
      "Name": "Coenen & Co.",
      "Strasse": "Elzas Passage",
      "Hausnr": "17-19",
      "PLZ": "5701 RW",
      "Ort": "Helmond",
      "Webseite": "https://www.coenenhelmond.nl/",
      "Telefonnummer": "0492 522005",
      "Mail": "info@coenenhelmond.nl",
      "Logo": "",
      "Coordinates": "51.4803259, 5.654884",
      "Logopfad": ""
    },{
      "Id": 141,
      "Name": "Kitchen 2 Table",
      "Strasse": "Voorstraat",
      "Hausnr": "9",
      "PLZ": "3441 CA ",
      "Ort": "Woerden",
      "Webseite": "https://kitchen2Table.nl",
      "Telefonnummer": "0348 412465",
      "Mail": "info@kitchen2table.nl",
      "Logo": "",
      "Coordinates": "52.0875179, 4.8841793",
      "Logopfad": ""
    },{
      "Id": 142,
      "Name": "Kookings Kookwinkel",
      "Strasse": "Langstraat",
      "Hausnr": "46",
      "PLZ": "6001 CW",
      "Ort": "Weert",
      "Webseite": "https://www.facebook.com/kookingskookwinkelweert/",
      "Telefonnummer": "0495 540833",
      "Mail": "kookings@planet.nl",
      "Logo": "",
      "Coordinates": "51.2514123, 5.7054457",
      "Logopfad": ""
    },{
      "Id": 143,
      "Name": "Koken & Trends",
      "Strasse": "Hoofdstraat",
      "Hausnr": "64",
      "PLZ": "8162 AK",
      "Ort": "Epe",
      "Webseite": "https://www.kokenentrends.nl/",
      "Telefonnummer": "0578 611722",
      "Mail": "info@kokenentrends.nl",
      "Logo": "",
      "Coordinates": "52.3493309, 5.9838517",
      "Logopfad": ""
    },{
      "Id": 144,
      "Name": "Van 't Ende v.o.f.",
      "Strasse": "Dorpsstraat",
      "Hausnr": "14",
      "PLZ": "7683 BJ",
      "Ort": "Den Ham",
      "Webseite": "https://www.vantende.nl/",
      "Telefonnummer": "0546 671444",
      "Mail": "klantenservice@vantende.nl",
      "Logo": "",
      "Coordinates": "52.4621848, 6.4965005",
      "Logopfad": ""
    },{
      "Id": 145,
      "Name": "Voss",
      "Strasse": "Hoofdstraat",
      "Hausnr": "228",
      "PLZ": "2071 EP ",
      "Ort": "Santpoort-Noord",
      "Webseite": "https://www.voss.tv/",
      "Telefonnummer": "023 5378764",
      "Mail": "ericvoss@planet.nl",
      "Logo": "",
      "Coordinates": "52.4292803, 4.6439253",
      "Logopfad": ""
    },{
      "Id": 146,
      "Name": "Zeiler Lichtenvoorde",
      "Strasse": "Rapenburgsestraat",
      "Hausnr": "32",
      "PLZ": "7131 CZ",
      "Ort": "Lichtenvoorde",
      "Webseite": "",
      "Telefonnummer": "0544 371419",
      "Mail": "info@zeilerlichtenvoorde.nl",
      "Logo": "",
      "Coordinates": "51.9877805, 6.5652108",
      "Logopfad": ""
    }
  ]
}
