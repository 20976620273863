export default {
  Produkte:
  [
{
      "Marke": "Spring",
      "Name": "Topf-Set 4tlg. FINESSE 2+",
      "Beschreibung": "Edelstahl rostfrei / Silikon - 4-teilig, bewährtes SPRING Mehrschichtmaterial, wärmeisolierende Griffe, Schüttrand mit Ausgießfunktion",
      "Einzelpreis": "399,00",
      "AlterPreis": "576,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0680800604.jpg"},{"src": "0680800604_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Spring",
      "Name": "Bratpfanne VULCANO CLASSIC",
      "Beschreibung": "Bewährtes SPRING Mehrschichtmaterial mit 3-fach Antihaftversiegelung, Erhältlich in Ø 24 cm & 28 cm",
      "Einzelpreis": "79,00",
      "AlterPreis": "99,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1484786024.jpg"},{"src": "1484786024_a.jpg"},{"src": "1484786024_e.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Spring",
      "Name": "Bratpfanne VULCANO CUT RESIST",
      "Beschreibung": "Antihaftbeschichtung mit erhabener Wabenstruktur – Erhältlich in Ø 24 cm & Ø 28 cm, für Pfannenwender aus Metall geeignet, bewährtes SPRING Mehrschichtmaterial",
      "Einzelpreis": "99,00",
      "AlterPreis": "119,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1484946024.jpg"},{"src": "1484946024_a.jpg"},{"src": "1484946024_d.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Spring",
      "Name": "Wokpfanne CANTON 30 cm",
      "Beschreibung": "Carbonstahl  – extra leicht, mit Holzgriff, besonders robust durch Wärmebehandlung",
      "Einzelpreis": "69,00",
      "AlterPreis": "89,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1692116030.jpg"},{"src": "1692116030_b.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Spring",
      "Name": "Fondue-Garnitur DAVOS 17 cm",
      "Beschreibung": "Edelstahl - inkl. Spritzschutz, Rechaud mit Pastenbrenner, 6 x Fondue-Gabeln, Induktionsgeeignet",
      "Einzelpreis": "",
      "AlterPreis": "119,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2698416018.jpg"},{"src": "2698416018_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Spring",
      "Name": "Fondue-Garnitur DAVOS CULINOX 17 cm",
      "Beschreibung": "Edelstahl verkupfert - inkl. Spritzschutz, Rechaud mit Pastenbrenner, 6 x Fondue-Gabeln",
      "Einzelpreis": "",
      "AlterPreis": "129,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2698426018.jpg"},{"src": "2698426018_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Spring",
      "Name": "Raclette8 CLASSIC",
      "Beschreibung": "Erhältlich mit Aluminiumgrillplatte oder Granitstein, inkl. 8 Pfännchen und 8 Schabern ",
      "Einzelpreis": "",
      "AlterPreis": "179,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "3267310001.jpg"},{"src": "3267410001.jpg"},{"src": "3267310001_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Spring",
      "Name": "Fondue-Garnitur SAAS-FEE",
      "Beschreibung": "Gusseisen, emailliert – Erhältlich in schwarz & rot, inkl. Pastenbrenner und 8 Fondue-Gabeln",
      "Einzelpreis": "89,00",
      "AlterPreis": "99,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2892516020.jpg"},{"src": "2892566020.jpg"},{"src": "2892516020_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Spring",
      "Name": "Gasbrenner 7 cm",
      "Beschreibung": "Stufenlos regulierbar, hohe Heizleistung 0,5 kW",
      "Einzelpreis": "",
      "AlterPreis": "59,90",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2823306807.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Spring",
      "Name": "PizzaRaclette6",
      "Beschreibung": "Mit Aluminiumgrillplatte - Erhältlich in schwarz & silber inkl. 6 Pfännchen und 6 Schabern",
      "Einzelpreis": "",
      "AlterPreis": "199,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "3367710004.jpg"},{"src": "3367710002_c.jpg"},{"src": "3367710004_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bratentopf PROVENCE rund, pearl grey",
      "Beschreibung": "Gusseisen, emailliert - Erhältlich in Ø 24 cm & 26 cm",
      "Einzelpreis": "139,00",
      "AlterPreis": "179,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0401000726.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bratentopf PROVENCE oval, pearl grey",
      "Beschreibung": "Gusseisen, emailliert - Erhältlich in Ø 33 cm & 35 cm",
      "Einzelpreis": "189,00",
      "AlterPreis": "229,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0402000733.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bratentopf PROVENCE rund, racing green",
      "Beschreibung": "Gusseisen, emailliert - Erhältlich in Ø 24 cm & 26 cm",
      "Einzelpreis": "139,00",
      "AlterPreis": "179,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0401001126.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bratentopf PROVENCE oval, racing green",
      "Beschreibung": "Gusseisen, emailliert - Erhältlich in Ø 33 cm & 35 cm",
      "Einzelpreis": "189,00",
      "AlterPreis": "229,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0402001133.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bratpfanne PROVENCE, 28 cm",
      "Beschreibung": "Griff aus Edelstahl rostfrei / Gusseisen, emailliert - Erhältlich in rot & schwarz",
      "Einzelpreis": "79,99",
      "AlterPreis": "89,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0403001028.jpg"},{"src": "0403001428.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bratpfanne PROVENCE hoch, 28 cm",
      "Beschreibung": "Griff aus Edelstahl rostfrei / Gusseisen, emailliert - Erhältlich in rot & schwarz",
      "Einzelpreis": "89,99",
      "AlterPreis": "99,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0403051028.jpg"},{"src": "0403051428.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bauernpfanne PROVENCE mit Glasdeckel, schwarz",
      "Beschreibung": "Glasdeckel / Gusseisen, emailliert - Erhältlich in Ø 24 cm & Ø 28 cm",
      "Einzelpreis": "69,99",
      "AlterPreis": "99,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0402551028.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Bauernpfanne PROVENCE mit Glasdeckel, rot",
      "Beschreibung": "Glasdeckel / Gusseisen, emailliert - Erhältlich in Ø 24 cm & Ø 28 cm",
      "Einzelpreis": "69,99",
      "AlterPreis": "99,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0402550428.jpg"},{"src": "0402550428_a.jpg"},{"src": "0402550428_b.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "cilio",
      "Name": "Waffeleisen CLASSIC, Herz",
      "Beschreibung": "Antihaftbeschichtete Backfläche: Ø 16,5 cm, 5 Bräunungsgrade",
      "Einzelpreis": "44,99",
      "AlterPreis": "54,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "492699.jpg"},{"src": "492699_b.jpg"},{"src": "492699_c.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Wasserkocher CLASSIC VESUVIO",
      "Beschreibung": "Edelstahl rostfrei / Glas - Inhalt 1,7 L, Herausnehmbarer Kalkfilter, Skala zum Abmessen des Wasserstandes",
      "Einzelpreis": "54,99",
      "AlterPreis": "67,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "492682_a.jpg"},{"src": "492682_c.jpg"},{"src": "492682_b.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Kaffeefilter Gr. 4 schwarz matt",
      "Beschreibung": "Hartporzellan - Handaufguss für ein wohlschmeckendes Kaffeearoma,  Für Papierfilter Größe 4",
      "Einzelpreis": "16,99",
      "AlterPreis": "21,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "106190_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Espressotasse ROMA schwarz matt",
      "Beschreibung": "Porzellan - extra dickwandig, mit Untertasse",
      "Einzelpreis": "9,49",
      "AlterPreis": "11,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "215328.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Cappuccinotasse ROMA schwarz matt",
      "Beschreibung": "Porzellan - extra dickwandig, mit Untertasse",
      "Einzelpreis": "14,29",
      "AlterPreis": "17,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "215335.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Espressokocher AIDA 6T, elektrisch",
      "Beschreibung": "Edelstahl rostfrei - für 6 Tassen, mit Warmhaltefunktion",
      "Einzelpreis": "79,99",
      "AlterPreis": "94,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "273694.jpg"},{"src": "273694_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Espressokocher AIDA",
      "Beschreibung": "Edelstahl rostfrei - erhältlich als 2, 4, 6 & 10 Tassen, Induktionsgeeignet",
      "Einzelpreis": "49,99",
      "AlterPreis": "64,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "342246.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "cilio",
      "Name": "Wasserkessel NERO",
      "Beschreibung": "Edelstahl lackiert / Akazienholz - Inhalt 2 L, mit harmonischer Flöte, Induktionsgeeignet",
      "Einzelpreis": "59,99",
      "AlterPreis": "76,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "430837.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Isolierkanne VENEZIA",
      "Beschreibung": "Edelstahl lackiert - erhältlich in Roségold, Edelstahl, weiß, rot, schwarz matt, Inhalt 1 L, mit praktischer Einhand-Ausgießtaste",
      "Einzelpreis": "29,99",
      "AlterPreis": "39,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "543858.jpg"},{"src": "543926.jpg"},{"src": "543933.jpg"},{"src": "543940.jpg"},{"src": "543865.jpg"},{"src": "543926_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "cilio",
      "Name": "Untersetzer LANA, quadratisch (21 x 21 cm)",
      "Beschreibung": "Schafwolle - hitzebeständig und waschbar, handgefertigt in Nepal, erhältlich in grau, grün, rot, schwarz, blau & bunt",
      "Einzelpreis": "14,99",
      "AlterPreis": "19,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "469929.jpg"},{"src": "469905.jpg"},{"src": "469982.jpg"},{"src": "469943.jpg"},{"src": "469967.jpg"},{"src": "469981.jpg"},{"src": "469967_b.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "cilio",
      "Name": "Untersetzer LANA, rund (Ø 21 cm)",
      "Beschreibung": "Schafwolle - hitzebeständig und waschbar, handgefertigt in Nepal, erhältlich in grau, grün, rot, schwarz, blau & bunt",
      "Einzelpreis": "14,99",
      "AlterPreis": "19,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "469998.jpg"},{"src": "469912.jpg"},{"src": "469936.jpg"},{"src": "469950.jpg"},{"src": "469974.jpg"},{"src": "469899.jpg"},{"src": "469936_b.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Zassenhaus",
      "Name": "Elektrische Kaffeemühle EXPERT",
      "Beschreibung": "Kegelmahlwerk aus gehärtetem Stahl, Mahlgradeinstellung in 10 Stufen, Mengenauswahl 2-12 Tassen, besonders geräuscharm",
      "Einzelpreis": "149,00",
      "AlterPreis": "169,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "043044_a.jpg"},{"src": "043044_e.jpg"},{"src": "043044_d.jpg"},{"src": "043044_c.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Zassenhaus",
      "Name": "Digitale Kaffeewaage EXPERT",
      "Beschreibung": "0,1 g-Schritte bis 3 kg, hitzebeständige Wiegefläche, mit integriertem Timer, inkl. USB Kabel",
      "Einzelpreis": "29,99",
      "AlterPreis": "39,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "073539_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Teesieb PROFI ",
      "Beschreibung": "Edelstahl rostfrei - Praktisch als Einsatz in Kannen, großen Tassen, Bechern und Gläsern, inkl Deckel",
      "Einzelpreis": "12,99",
      "AlterPreis": "17,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1045302800.jpg"},{"src": "1045302800_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Teekanne ASSAM",
      "Beschreibung": "Borosilikatglas/Edelstahl rostfrei - Inhalt 1 L, 5 Tassen, inkl. Siebeinsatz",
      "Einzelpreis": "36,99",
      "AlterPreis": "44,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1045743500_a.jpg"},{"src": "1045743500.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Teekanne YASMIN ",
      "Beschreibung": "Gusseisen - inklusive Filtereinsatz aus Edelstahl, Inhalt 800 ml, für 6 Tassen",
      "Einzelpreis": "49,99",
      "AlterPreis": "69,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1045901000.jpg"},{"src": "1045901000_b.jpg"},{"src": "1045901000_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Spring",
      "Name": "Auflaufform rechteckig CHALET rot",
      "Beschreibung": "Feuerfeste Ofenkeramik - erhältlich in 28,5 cm, 35 cm & 40 cm, Ofenfest bis 270 °C und stapelbar, Ideal zum Gratinieren, Servieren und Aufbewahren",
      "Einzelpreis": "19,90",
      "AlterPreis": "24,90",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "3720405624.jpg"},{"src": "3720355621_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Spring",
      "Name": "Auflaufform oval CHALET rot",
      "Beschreibung": "Feuerfeste Ofenkeramik - erhältlich in 26 cm & 30 cm, Ofenfest bis 270 °C und stapelbar, Ideal zum Gratinieren, Servieren und Aufbewahren",
      "Einzelpreis": "16,90",
      "AlterPreis": "19,90",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "3730265626.jpg"},{"src": "3730265626_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Spring",
      "Name": "Handschuh GRIPS kurz ",
      "Beschreibung": "Erhältlich in schwarz, rot & rot/schwarz, 2 Stück im Set, Temperaturbeständig bis 250 °C",
      "Einzelpreis": "",
      "AlterPreis": "34,90",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2094055102.jpg"},{"src": "2094055602.jpg"},{"src": "2094056602.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Spring",
      "Name": "Handschuh GRIPS lang ",
      "Beschreibung": "Erhältlich in schwarz, rot & rot/schwarz, 2 Stück im Set, Temperaturbeständig bis 250 °C",
      "Einzelpreis": "",
      "AlterPreis": "39,90",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2094065102.jpg"},{"src": "2094065602.jpg"},{"src": "2094066602.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Zassenhaus",
      "Name": "Pfeffer-/Salzmühle WESTERLAND schwarz/Eiche",
      "Beschreibung": "Buchenholz lackiert / Eichenholz geölt - erhältlich in 14 cm & 18 cm, mit original ZASSENHAUS Mahlwerk Made in Germany",
      "Einzelpreis": "29,99",
      "AlterPreis": "37,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "023817.jpg"},{"src": "023824.jpg"},{"src": "023855.jpg"},{"src": "023862.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Pfeffer-/Salzmühle WESTERLAND weiß/Eiche",
      "Beschreibung": "Buchenholz lackiert / Eichenholz geölt - erhältlich in 14 cm & 18 cm, mit original ZASSENHAUS Mahlwerk Made in Germany",
      "Einzelpreis": "29,99",
      "AlterPreis": "37,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "023831.jpg"},{"src": "023848.jpg"},{"src": "023879.jpg"},{"src": "023886.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Arbeitsbrett STATION ONE, Bambus",
      "Beschreibung": "Bambus/Edelstahl - mit integrierter Auffangschale (2/3 GN), Schneidbrett auch separat einsetzbar, Griffmulden ideal zum Einsatz von Tellern und Schüsseln, 46 x 35 cm",
      "Einzelpreis": "74,99",
      "AlterPreis": "94,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "054323_a.jpg"},{"src": "054323_b.jpg"},{"src": "054323_g.jpg"},{"src": "054323_e.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "ZH",
      "Name": "Schneidmatte, grau PREMIUM LINE",
      "Beschreibung": "Erhältlich in 25 x 16 cm, 32 x 20 cm & 38 x 25 cm, flexibel, temperaturbeständig von -20 °C bis +150 °C, spülmaschinenfest ",
      "Einzelpreis": "5,99",
      "AlterPreis": "8,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "061673.jpg"},{"src": "061734.jpg"},{"src": "061680.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Schneidmatte, rot PREMIUM LINE",
      "Beschreibung": "Erhältlich in 25 x 16 cm, 32 x 20 cm & 38 x 25 cm, flexibel, temperaturbeständig von -20 °C bis +150 °C, spülmaschinenfest ",
      "Einzelpreis": "5,99",
      "AlterPreis": "8,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "061697.jpg"},{"src": "061703.jpg"},{"src": "061741_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Schneidmatte, schwarz PREMIUM LINE",
      "Beschreibung": "Erhältlich in 25 x 16 cm, 32 x 20 cm & 38 x 25 cm, flexibel, temperaturbeständig von -20 °C bis +150 °C, spülmaschinenfest ",
      "Einzelpreis": "5,99",
      "AlterPreis": "8,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "061758.jpg"},{"src": "061765.jpg"},{"src": "061772.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Gewürzreibe",
      "Beschreibung": "Gusseisen / Deckel aus Buchenholz - mit Vorratsfach, Ø 8 cm x 9 cm",
      "Einzelpreis": "29,99",
      "AlterPreis": "39,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "076080_d.jpg"},{"src": "076080_a.jpg"},{"src": "076080_c.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "ZH",
      "Name": "Gewürzreibe XL ",
      "Beschreibung": "Gusseisen / Deckel aus Buchenholz - mit Vorratsfach, Ø 11 cm x 7 cm",
      "Einzelpreis": "39,99",
      "AlterPreis": "49,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "076141.jpg"},{"src": "076141_a.jpg"},{"src": "076141_c.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "ZH",
      "Name": "Pfeffer-/Salzmühle KIEL",
      "Beschreibung": "Eichenholz - erhältlich in 18 cm & 24 cm, mit original ZASSENHAUS Mahlwerk Made in Germany",
      "Einzelpreis": "49,99",
      "AlterPreis": "59,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "023770.jpg"},{"src": "023787.jpg"},{"src": "023794.jpg"},{"src": "023800.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Messerblock",
      "Beschreibung": "Eichenholz - für bis zu 12 Messer bis 25 cm Klingenlänge ",
      "Einzelpreis": "49,99",
      "AlterPreis": "59,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "078176_b.jpg"},{"src": "078176.jpg"},{"src": "078176_d.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "ZH",
      "Name": "Schneidbrett",
      "Beschreibung": "Eichenholz - erhältlich in 26 x 17 cm & 36 x 23 cm",
      "Einzelpreis": "19,99",
      "AlterPreis": "24,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "057188_a.jpg"},{"src": "057195_a.jpg"},{"src": "057195.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Griff-/Servierbrett",
      "Beschreibung": "Eichenholz - erhältlich in 38 x 17,5 cm & 58 x 20,5 cm",
      "Einzelpreis": "34,99",
      "AlterPreis": "44,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "057225_a.jpg"},{"src": "057218.jpg"},{"src": "057225.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "ZH",
      "Name": "Hackblock",
      "Beschreibung": "Eiche Stirnholz - erhältlich in 45 x 25 x 4 cm & 54 x 30 x 4 cm",
      "Einzelpreis": "79,99",
      "AlterPreis": "99,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "057294.jpg"},{"src": "057201.jpg"},{"src": "057294_b.jpg"},{"src": "057201_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Passiergerät PROFI",
      "Beschreibung": "Edelstahl rostfrei - Ø 20 cm, inkl. 4 Scheiben für professionelles Passieren",
      "Einzelpreis": "59,99",
      "AlterPreis": "79,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0966002820.jpg"},{"src": "0966002820_a.jpg"},{"src": "0966002820_b.jpg"},{"src": "0966002820_c.jpg"},{"src": "0966002820_d.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Trommelreibe ROBUSTO",
      "Beschreibung": "Aluminiumguss - inkl. 3 Reibtrommeln, extra hoher Fuß, Saugfuß für sicheren Halt ",
      "Einzelpreis": "89,99",
      "AlterPreis": "119,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1310223000_f.jpg"},{"src": "1310223000.jpg"},{"src": "1310223000_g.jpg"},{"src": "1310223000_b.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Gemüsehobel COMPACT",
      "Beschreibung": "Hochwertiger Kunststoff / Klingenstahl - für Scheiben und Stifte, mit V-Klinge, stufenlos verstellbare Schnittstärke",
      "Einzelpreis": "24,99",
      "AlterPreis": "34,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0900451000.jpg"},{"src": "1310223000.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Multizerkleinerer STYLE, elektrisch",
      "Beschreibung": "2 Geschwindigkeitsstufen, Glasbehälter mit Anti-Rutsch-Ring, Inhalt 2 L",
      "Einzelpreis": "39,99",
      "AlterPreis": "59,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1705002800.jpg"},{"src": "1705002800_e.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Kartoffelpresse PRIMUS",
      "Beschreibung": "Edelstahl rostfrei - stabile Bauart, leichte Reinigung, Länge 30 cm ",
      "Einzelpreis": "34,99",
      "AlterPreis": "49,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1310702800_a.jpg"},{"src": "1310702800_b.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Rührlöffel Kollektion TOSCANA 30 cm",
      "Beschreibung": "Olivenholz - einzeln in Handarbeit gefertigt, mit Sonnenblumenöl geölt, Länge 30 cm",
      "Einzelpreis": "6,99",
      "AlterPreis": "8,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "182002.jpg"},{"src": "182033.jpg"},{"src": "182057.jpg"},{"src": "182064.jpg"},{"src": "182071.jpg"},{"src": "182125.jpg"},{"src": "182132.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Mörser GOLIATH",
      "Beschreibung": "Granit - inkl. Stößel, abriebfest und säureresistent, Ø 18 cm",
      "Einzelpreis": "36,99",
      "AlterPreis": "46,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "420128.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Mörser PLUTOS",
      "Beschreibung": "Granit - inkl. Stößel, abriebfest und säureresistent, Ø 10 cm",
      "Einzelpreis": "19,99",
      "AlterPreis": "24,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "420807.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Pfeffer- & Salzmühle BARI",
      "Beschreibung": "Edelstahl / Glas - mit Keramikmahlwerk, Höhe 14 cm",
      "Einzelpreis": "19,99",
      "AlterPreis": "28,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "613445.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Cocktail-Shaker mit Rezepten",
      "Beschreibung": "Glas / Edelstahl - mit 6 Rezepten auf dem Glas, Inhalt 400 ml",
      "Einzelpreis": "14,99",
      "AlterPreis": "18,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "200300_a.jpg"},{"src": "200300.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Caipirinha-Stößel, verkupfert",
      "Beschreibung": "Edelstahl verkupfert / Kunststoff - Länge 24 cm",
      "Einzelpreis": "14,49",
      "AlterPreis": "17,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "200379.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Becher MOSCOW MULE",
      "Beschreibung": "Edelstahl verkupfert – Inhalt 500 ml, mit und ohne Hammerschlag",
      "Einzelpreis": "14,99",
      "AlterPreis": "19,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "200409.jpg"},{"src": "200416.jpg"},{"src": "200409_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Bar Set MANHATTAN",
      "Beschreibung": "Edelstahl kupferfarben - 5-teilig: Cocktailshaker, Cocktaillöffel, Barmaß, Barsieb, Eiszange",
      "Einzelpreis": "39,99",
      "AlterPreis": "49,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "202427.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Weinkühler VINO",
      "Beschreibung": "Edelstahl - Fassungsvermögen 4 L",
      "Einzelpreis": "29,99",
      "AlterPreis": "39,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "202434_a.jpg"},{"src": "202434.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Eiseimer FREDDO",
      "Beschreibung": "Edelstahl satiniert – doppelwandig, Deckel mit Öffnung zur einfachen Aufbewahrung der Zange, Inhalt 1,3 L",
      "Einzelpreis": "29,99",
      "AlterPreis": "39,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "150957.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Profi-Saftpresse AMALFI",
      "Beschreibung": "Zink-Druckguss kupferfarben - für alle Zitrusfrüchte, stabile Profi-Qualität, Gewicht 4,9 kg, Höhe geöffnet 72 cm",
      "Einzelpreis": "145,00",
      "AlterPreis": "179,00",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "309454.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "cilio",
      "Name": "Zitruspressen",
      "Beschreibung": "Metall - Erhältlich in 3 Größen und in grün, gelb & orange, einfache Handhabung, Kerne werden zuverlässig aufgefangen",
      "Einzelpreis": "7,99",
      "AlterPreis": "9,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "293401.jpg"},{"src": "293418.jpg"},{"src": "293425.jpg"},{"src": "293425_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Käsereibe, Edelstahl",
      "Beschreibung": "Edelstahl - besonders leichtes Reiben ",
      "Einzelpreis": "29,99",
      "AlterPreis": "38,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1310212800.jpg"},{"src": "1310212800_a.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Seiher DELUXE",
      "Beschreibung": "Edelstahl - Erhältlich in 18 cm, 22 cm & 26 cm, auch als Schale verwendbar, breite Auflage für sicheren Halt",
      "Einzelpreis": "18,99",
      "AlterPreis": "22,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2506002818.jpg"},{"src": "2506002822.jpg"},{"src": "2506002826.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Nudelmaschine VITALE 150",
      "Beschreibung": "Edelstahl - Frische Pasta schnell selbst gemacht, Für Lasagne, Tagliolini, Tagliatelle, mit Tischklemme",
      "Einzelpreis": "59,99",
      "AlterPreis": "89,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0807702800.jpg"},{"src": "0807702800_a.jpg"},{"src": "0807702800_b.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Pizza-Stein quadratisch mit Gestell",
      "Beschreibung": "Pizza wie vom Italiener, feuerfestes Material, auch für den Grill geeignet, 35 x 38 cm",
      "Einzelpreis": "29,99",
      "AlterPreis": "39,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1086000000.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Tortenring verstellbar, rund",
      "Beschreibung": "Edelstahl - erhältlich in hoch & extra hoch, verstellbar von 16-30 cm",
      "Einzelpreis": "11,99",
      "AlterPreis": "16,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0805832800.jpg"},{"src": "0805832815_a.jpg"},{"src": "0805832800_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Messbecher, Glas",
      "Beschreibung": "Glas - erhältlich als 500 ml & 1 L, mit praktischer Skala",
      "Einzelpreis": "8,99",
      "AlterPreis": "12,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0912003505.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Rührbecher, rot BAKE",
      "Beschreibung": "Kratzfester Kunststoff (100 % Melamin) - Inhalt 1 L, Rutschfester Standfuß, Inklusive Griff und Ausgießer, mit praktischer Innenskala in ml und cups",
      "Einzelpreis": "11,99",
      "AlterPreis": "14,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2505201410.jpg"},{"src": "2505201410_d.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Teigschüssel, rot BAKE",
      "Beschreibung": "Kratzfester Kunststoff (100 % Melamin) - Erhältlich als 3 L & 4 L, Rutschfester Standfuß, Inklusive Griff und Ausgießer",
      "Einzelpreis": "14,99",
      "AlterPreis": "19,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "2505201430.jpg"},{"src": "2505201430_d.jpg"},{"src": "2505201440_d.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Baguetteblech BAKE ONE",
      "Beschreibung": "Sauerteigbeständig, Stahlblech mit Antihaftversiegelung",
      "Einzelpreis": "14,99",
      "AlterPreis": "19,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "0810061038.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Dauerbackfolie, 2er-Set",
      "Beschreibung": "Wiederverwendbar und leicht zu reinigen, Temperaturbeständig bis 260 °C, 40 x 32,5 cm",
      "Einzelpreis": "8,99",
      "AlterPreis": "12,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1039950002.jpg"},{"src": "1039950002_b.jpg"},{"src": "1039950002_c.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Küchenprofi",
      "Name": "Teigrolle Marmor",
      "Beschreibung": "Mit Griffen und Ablage aus Holz, Länge 47 cm",
      "Einzelpreis": "24,99",
      "AlterPreis": "34,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "1002481047.jpg"},],
      "Website": "",
      "variant": "0"      
    },{
      "Marke": "Zassenhaus",
      "Name": "Digitale Küchenwaage BALANCE",
      "Beschreibung": "Wiegefläche aus Glas - Erhältlich in schwarz, weiß, rot, creme, cool grey, Kapazität: max. 5 kg, Wiegeeinheit: 1 g",
      "Einzelpreis": "19,99",
      "AlterPreis": "27,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "073195.jpg"},{"src": "073201.jpg"},{"src": "073218.jpg"},{"src": "073256.jpg"},{"src": "073485.jpg"},{"src": "073195_a.jpg"},],
      "Website": "",
      "variant": "1"      
    },{
      "Marke": "Zassenhaus",
      "Name": "Teigschaber SMILEY",
      "Beschreibung": "Silikon / Griff aus Buchenholz - Erhältlich in verschiedenen Motiven, 30,5 cm",
      "Einzelpreis": "8,99",
      "AlterPreis": "10,99",
      "VarianteA": "",
      "VarianteB": "",
      "VarianteC": "",
      "PreisA": "",
      "PreisB": "",
      "Bild Name": "",
      "DurchmesserA": "",
      "DurchmesserB": "",
      "DurchmesserC": "",
      "DurchmesserD": "",
      "images": [{"src": "056976.jpg"},{"src": "056662.jpg"},{"src": "056679.jpg"},{"src": "056686.jpg"},{"src": "056983.jpg"},],
      "Website": "",
      "variant": "1"      
    }
  ]
}
