<template>
    <v-card outlined>
      <v-carousel
          height="200"
          hide-delimiter-background
          hide-delimiters
          :show-arrows="showArrows"
          :continuous="false"
          :cycle="false"
      >
        <v-carousel-item
            v-for="(carouselitem,i) in item.images"
            :key="i"
        >
          <v-img contain height="200px" :src="getImgUrl(carouselitem.src)"></v-img>
        </v-carousel-item>
      </v-carousel>
    <!--
    <v-img :contain="!!item.Logopfad" height="150px" :src="logo"></v-img>
    -->
    <v-card-title class="text-h2">{{item.Name}}</v-card-title>
    <span class="text-price" v-if="item.variant == 1">ab </span>
    <span class="text-price" v-if="item.Einzelpreis">
      {{ item.Einzelpreis }}€* <span v-if="item.AlterPreis" class="text-h3">UVP<span v-if="item.variant == 1"> ab</span> {{ item.AlterPreis }}€</span>
    </span>
    <span class="text-price" v-else-if="item.AlterPreis">
      UVP {{ item.AlterPreis }}€*
    </span>
    <span class="text-price" v-if="item.PreisA">{{item.PreisA }}€ bis </span>
    <span class="text-price" v-if="item.PreisB">{{item.PreisB }}€*</span>
    <span class="text-price" v-if="item.PreisC">{{item.PreisC }}€</span>
      <v-expansion-panels flat>
      <v-expansion-panel
      >
        <v-expansion-panel-header>
          Mehr Infos
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <span class="text-h3">{{ item.Beschreibung }} </span> <br>
          <span class="text-h3" v-if="item.VarianteA">
            {{item.VarianteA}} <span v-if="item.DurchmesserA">Ø: {{item.DurchmesserA}}</span> <span v-if="item.PreisA">Preis: {{item.PreisA }}€</span>
          </span>
          <span class="text-h3" v-if="item.VarianteB">
            {{item.VarianteB}} <span v-if="item.DurchmesserB">Ø: {{item.DurchmesserB}}</span> <span v-if="item.PreisB">Preis: {{item.PreisB }}€</span>
          </span>
          <span class="text-h3" v-if="item.VarianteC">
            {{item.VarianteC}}<span v-if="item.DurchmesserC">Ø: {{item.DurchmesserC}}</span>  <span v-if="item.PreisC">Preis: {{item.PreisC }}€</span>
          </span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions>
      <v-spacer />
      <v-btn href="/#stores" text color="primary">Händler Finden <v-icon>mdi-map-marker-outline</v-icon></v-btn>&nbsp;
      <v-btn text @click="openPdf()" color="primary">PDF <v-icon>mdi-file-download</v-icon></v-btn>
      <v-spacer />
    </v-card-actions>
</v-card>
</template>

<script>
export default {
  props: {
    item: Object
  },
  computed: {
    logo() {
      return this.item.Logopfad ? require('@/assets/produkte/'+this.item.Logopfad) : require('@/assets/dummyFood.jpg')
    },
    showArrows(){
      if(this.item.images && this.item.images.length > 1)
        return true;

      return false;
    }
  },
  methods: {
    openPdf() {
      const link = "./Küchentrends_Katalog_2023.pdf"
      window.open(link, 'blank')
    },
    getImgUrl(imageName) {
      return imageName ? require('@/assets/produkte/' + imageName) : ''
    }
  }
}
</script>
<style lang="css">
.v-expansion-panel-content__wrap {
  padding: 0px 5px
}
</style>

