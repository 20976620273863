import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueScrollactive from 'vue-scrollactive';
import '@mdi/font/css/materialdesignicons.css'
Vue.config.productionTip = false
import "@/assets/main.scss"
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
//import * as VueGoogleMaps from 'vue2-google-maps'
/*Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API,
    libraries: 'places'
  },
  installComponents: true
})*/
Vue.use(VueGoogleMaps,{
  installComponents: true
});
Vue.use(VueScrollactive);
Vue.mixin({
  /*
  data: function() {
    return {
      ActionAvailable: false
    }
  }
  */
  computed: {
    ActionAvailable: function () {
      var available = false;
      if(this.$route.query.dev == "7234FHA7619JDGFA")
        available = true;
      return available;
    }
  }
})
new Vue({
  vuetify,
  iconfont: 'mdi',
  router,
  render: h => h(App)
}).$mount('#app')
