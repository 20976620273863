<template>
  <v-container>
    <v-card-title class="text-h1">
      Datenschutz
    </v-card-title>
    <v-card-title>
      Allgemeine Hinweise
    </v-card-title>
    <v-card-text class="text-left">
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
    </v-card-text>
    <v-card-title>
      Datenerfassung auf unserer Website
    </v-card-title>
    <v-card-title>
      Wer ist verantwortlich für die Datenerfassung auf dieser Website?
    </v-card-title>
    <v-card-text class="text-left">
      Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
    </v-card-text>
    <v-card-title>
      Wie erfassen wir Ihre Daten?
    </v-card-title>
    <v-card-text class="text-left">
      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben. <br>
Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
    </v-card-text>
    <v-card-title class="text-h1">
      2. Allgemeine Hinweise und Pflichtinformationen
    </v-card-title>
    <v-card-title>
      Datenschutz
    </v-card-title>
    <v-card-text class="text-left">
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.

Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.

Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </v-card-text>
    <v-card-title>
      Hinweis zur verantwortlichen Stelle
    </v-card-title>
    <v-card-text class="text-left">
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist: <br>
<br>
KÜCHENPROFI GmbH <br>
Höhscheider Weg 29 <br>
42699 Solingen <br>

Telefon: 0212 / 6582-0 <br>
E-Mail: verkauf@kuechenprofi.de <br>

Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
    </v-card-text>
    <v-card-title>
      Widerruf Ihrer Einwilligung zur Datenverarbeitung
    </v-card-title>
    <v-card-text class="text-left">
      Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
    </v-card-text>
        <v-card-title class="text-h1">
      3. Plugins und Tools
    </v-card-title>
      <v-card-title>
      Google Maps
    </v-card-title>
    <v-card-text class="text-left">
      Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.

Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.

Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.

Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: https://www.google.de/intl/de/policies/privacy/.

Quelle: www.erecht24.de
    </v-card-text>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>