import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from '../views/Datenschutz.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    depth: 0
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum,
    depth: 1
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz,
    depth: 1
  },
]
const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
