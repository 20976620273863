<template>
  <div>
  <v-row id="stores" justify="center">
    <!-- Auto Complete -->
      <v-col cols="8">
        <gmap-autocomplete
          id="searchTextField"
          placeholder="Suche nach deiner Stadt"
          @place_changed="setPlace"
          >
        </gmap-autocomplete>
      </v-col>
<!--       <v-col cols="1" class="pl-0">
        <v-btn elevation="0" color="primary" height="52px" @click="clear">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col> -->
      <!-- Liste Shops in der nähe -->
      <v-col v-if="this.zoom == 8" cols="12" sm="5" class="mr-2" style="height: 400px">
        <v-card-subtitle class="your-suppliers-headline">Einzelhändler in deiner Nähe:</v-card-subtitle>
        <v-list class="overflow-y-auto" :max-height="$vuetify.breakpoint.smAndUp ? '300px' : '190px'">
        <v-list>
      <v-expansion-panels popout v-if="filteredStandorte.length">
      <v-expansion-panel
          class="overflow-y-auto ma-2"
          v-for="(item, idx) in sortedFilterdListOfStores" :key="idx" :class="{'active': idx === 0}"
      >
        <v-expansion-panel-header>
          <div>
            <div style="margin-bottom: 10px">
              <strong>{{item.distance}} km</strong> entfernt:
            </div>
          <div>
            {{item.Name}} - {{item.Ort}}
          </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{item.Strasse}}&nbsp;{{item.Hausnr}}<br>
          <a v-if="item.Webseite" :href="item.Webseite" target="_blank">{{item.Webseite}}</a><br>
          {{item.Telefonnummer}} <br>
          <a v-if="item.Mail" :href="'mailto:'+item.Mail">{{item.Mail}}</a><br>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-subtitle v-else>
      Kein Store in der Nähe
    </v-card-subtitle>
        </v-list>
        </v-list>
      </v-col>
      <!--Google Map -->
      <v-col cols="12" :sm="this.zoom == 8 ? '6' : '8'">
      <gmap-map
        v-if="standorte"
        :center="center"
        :zoom="zoom"
        style="width: 100%; height: 400px; border: 6px solid rgb(128,128,128); border-radius: 10px">
        <gmap-marker
            :key="index"
            v-for="(m, index) in filteredStandorte"
            :position="m.Coordinates"
            :clickable="true"
            :draggable="false"
            :label="m.label"
            @click="openWindow(m)"

        />
        <gmap-info-window 
            @closeclick="window_open=false" 
            :opened="window_open" 
            :position="infoWindow.coords"
            :options="{
              pixelOffset: {
                width: 0,
                height: -35
              }
            }"
        >
        <div>
            <v-avatar  v-if="infoWindow.logoPfad">
              <v-img contain :src="require('@/assets/standorte/'+infoWindow.logoPfad)" />
            </v-avatar>
            {{ infoWindow.ort}} <br>
            {{ infoWindow.name }} <br>
            {{infoWindow.strasse}}&nbsp;{{infoWindow.hausnummer}}<br>
            <a v-if="infoWindow.webseite" :href="infoWindow.webseite" target="_blank">{{infoWindow.webseite}}</a><br>
            {{infoWindow.telefonnummer}} <br>
            {{infoWindow.mail}} <br>
        </div>
        </gmap-info-window>    
    </gmap-map>
      </v-col>
    </v-row>
  </div>
</template>  
<script>
import * as VueGoogleMaps from 'vue2-google-maps'
const MAX_UMKREIS = 60;
export default {
props: {
  standorte: {
    type: Array,
    default: () => null
  }
},
data() {
  return {
      center: {lat: 51.517233, lng: 8.856647},
      zoom: 6,
      markers: null,
      info_marker: null,
      infoWindow: {
        coords: null,
        name: '',
        strasse: '',
        telefonnummer: '',
        ort: '',
        hausnummer: '',
        webseite: '',
        mail: '',
        logoPfad: ''
      },
      window_open: false,
      plzSearch: null,
      ortSearch: null,
      place: null,
      query: null,
      search: null,
      sortedFilterdListOfStores: [],
    }
},
  beforeMount() {
    VueGoogleMaps.loadGmapApi({
      key: process.env.VUE_APP_GOOGLE_MAPS_API,
      libraries: 'places'
    })
  },
  computed: {
  filteredStandorte() {
   return this.standorte.filter((item) => {
    if(this.query) {
       return this.getDistance(item.Coordinates.lat, item.Coordinates.lng, this.query.location.lat, this.query.location.lng).inRadius
     }
     return true
   })
  }
},
watch: {
  filteredStandorte: {
    handler(val) {
      for(let i = 0 ; i < val.length; i++) {
        this.sortedFilterdListOfStores.push(
          {
            ...val[i],
            distance: this.getDistance(val[i].Coordinates.lat,val[i].Coordinates.lng, this.query.location.lat, this.query.location.lng).distance
          }
        )
      }
      this.sortedFilterdListOfStores.sort((a, b) => {
        return a.distance - b.distance
      })
    }
  }
},
    methods: {
        openWindow (marker) {
            this.window_open = true;
            this.infoWindow.coords = marker.Coordinates
            this.infoWindow.name = marker.Name
            this.infoWindow.strasse = marker.Strasse
            this.infoWindow.hausnummer = marker.Hausnr
            this.infoWindow.telefonnummer = marker.Telefonnummer
            this.infoWindow.webseite = marker.Webseite
            this.infoWindow.mail = marker.Mail
            this.infoWindow.logoPfad = marker.Logopfad
            this.infoWindow.ort = marker.Ort

        },
    // receives a place object via the autocomplete component
    setPlace(place) {
          this.place = place
          /* otherwise list gets longer */
          this.sortedFilterdListOfStores = []
          const { formatted_address } = this.place
          const city = formatted_address.split(',')[0]
          const newPosition = {
                  lat: this.place.geometry.location.lat(),
                  lng: this.place.geometry.location.lng(),
              }
          this.center = newPosition
             this.query = {
              name: city,
              location: newPosition
            }
          this.zoom = 8
      },
    clear() {
      this.query = null
      this.center = {lat: 49.559594, lng: 8.668679}
      this.zoom = 6
    },

  /* Checks if a store is in Radius MAX_UMKREIS Return True/False */
  getDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = this.toRad(lat2 - lat1);
  var dLon = this.toRad(lon2 - lon1);
  lat1 = this.toRad(lat1);
  lat2 = this.toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return {
    'inRadius': parseInt(d) <  MAX_UMKREIS,
    'distance': parseInt(d)
  }
},
// Converts numeric degrees to radians
    toRad(Value) {
        return Value * Math.PI / 180;
    }
    }  
}
</script>

<style>
#searchTextField {
    padding: 10px;
    border: 1px solid rgb(128, 128, 128);
    border-radius: 5px;
    font-size: 1rem;
    color: #5d5757;
    line-height: 30px;
    width: 100%;
}
.active {
  border: 5px solid rgb(61, 158, 61);
}
.active .v-expansion-panel-content__wrap {
  padding-bottom: 15px;
}
.your-suppliers-headline {
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}
</style>
